/* eslint-disable */
/** 
 * This file contains language specific message functions for tree-shaking. 
 * 
 *! WARNING: Only import messages from this file if you want to manually
 *! optimize your bundle. Else, import from the `messages.js` file. 
 * 
 * Your bundler will (in the future) automatically replace the index function 
 * with a language specific message function in the build step. 
 */


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_pathname = () => `/formations/[slug]`


/**
 * @param {{ slug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_pathname_generation = (params) => `/formations/${params.slug}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_pathname = () => `/formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pathname = () => `/formations/[slug]/[partNumber]/[sessionNumber]`


/**
 * @param {{ slug: NonNullable<unknown>, partNumber: NonNullable<unknown>, sessionNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pathname_generation = (params) => `/formations/${params.slug}/${params.partNumber}/${params.sessionNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const program_dashboard_pathname = () => `/formations/le-programme-rocket/tableau-de-bord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_pathname = () => `/accelerateurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_pathname = () => `/accelerateurs/[slug]/tableau-de-bord`


/**
 * @param {{ slug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_pathname_generation = (params) => `/accelerateurs/${params.slug}/tableau-de-bord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_entryDocument_pathname = () => `/accelerateurs/[slug]/justificatif-entree-en-formation`


/**
 * @param {{ slug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_entryDocument_pathname_generation = (params) => `/accelerateurs/${params.slug}/justificatif-entree-en-formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_content_pathname = () => `/accelerateurs/[slug]/[moduleNumber]/[partNumber]/[sessionNumber]`


/**
 * @param {{ slug: NonNullable<unknown>, moduleNumber: NonNullable<unknown>, partNumber: NonNullable<unknown>, sessionNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_content_pathname_generation = (params) => `/accelerateurs/${params.slug}/${params.moduleNumber}/${params.partNumber}/${params.sessionNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glossaries_pathname = () => `/glossaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glossary_pathname = () => `/glossaires/[slug]`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const term_pathname = () => `/glossaires/[slug]/[termSlug]`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_pathname = () => `/series/[slug]`


/**
 * @param {{ slug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_pathname_generation = (params) => `/series/${params.slug}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_pathname = () => `/series/[slug]/[seasonNumber]/[episodeNumber]`


/**
 * @param {{ slug: NonNullable<unknown>, seasonNumber: NonNullable<unknown>, episodeNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_pathname_generation = (params) => `/series/${params.slug}/${params.seasonNumber}/${params.episodeNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_pathname = () => `/connexion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_pathname = () => `/inscription`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_pathname = () => `/fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_pathname = () => `/redacteur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_files_pathname = () => `/redacteur/fichiers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_pathname = () => `/redacteur/articles/[slug]`


/**
 * @param {{ slug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_pathname_generation = (params) => `/redacteur/articles/${params.slug}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_pathname = () => `/mentor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_pathname = () => `/r/[slug]`


/**
 * @param {{ slug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_pathname_generation = (params) => `/r/${params.slug}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_pathname = () => `/articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_pathname = () => `/appel/[token]`


/**
 * @param {{ token: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_pathname_generation = (params) => `/appel/${params.token}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_pathname = () => `/formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_pathname = () => `/formateur/formations/[slug]`


/**
 * @param {{ slug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_pathname_generation = (params) => `/formateur/formations/${params.slug}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_pathname = () => `/certifications/[certificationNumber]`


/**
 * @param {{ certificationNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_pathname_generation = (params) => `/certifications/${params.certificationNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_pathname = () => `/u/[pseudo]`


/**
 * @param {{ pseudo: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_pathname_generation = (params) => `/u/${params.pseudo}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verifyAccount_pathname = () => `/verification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_pathname = () => `/onboarding`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_pathname = () => `/mot-de-passe-oublie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_pathname = () => `/tarifs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_pathname = () => `/devenir-formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_pathname = () => `/devenir-mentor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_pathname = () => `/mentions-legales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_pathname = () => `/confidentialite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_pathname = () => `/communaute`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_discussion_pathname = () => `/communaute/discussions/[id]`


/**
 * @param {{ id: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_discussion_pathname_generation = (params) => `/communaute/discussions/${params.id}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_pathname = () => `/cgu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_pathname = () => `/presse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_pathname = () => `/evenements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_dashboard = () => `Tableau de bord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_courses = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_accelerators = () => `Accélérateurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_glossaries = () => `Glossaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_doneYourself = () => `Auto-formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_articles = () => `Articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_discord = () => `Discord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_doneWithYou = () => `Accompagnement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_mentors = () => `Mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_tools = () => `Outils`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_new = () => `Nouveau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_needHelp = () => `Contactez-nous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_newVersionTitle = () => `Nouvelle version`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_newVersionDescription = () => `Vous aimez ? Vous avez des suggestions ? Dites-le nous sur Discord !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_needHelpButton = () => `Aide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TableOfContents_summary = () => `Sommaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_level = () => `Niv.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_xp = () => `XP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_pathname = () => `/compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_rocket_pathname = () => `/accelerateurs/rocket`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Term_languageChangedMessage = () => `Cette page n'est pas encore disponible dans votre langue, vous la voyez dans sa version originale.`


/**
 * @param {{ slug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glossary_pathname_generation = (params) => `/glossaires/${params.slug}`


/**
 * @param {{ slug: NonNullable<unknown>, termSlug: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const term_pathname_generation = (params) => `/glossaires/${params.slug}/${params.termSlug}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Term_noContentAvailableForThisTerm = () => `Il n'y a aucun contenu pour ce terme pour l'instant.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Glossary_aside_language = () => `Langue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Glossary_aside_availableLanguages = () => `Autres versions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_pathname = () => `/messagerie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Glossary_term_linkTitle = () => `En savoir plus sur ce terme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_meta_title = () => `Accélérateurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_meta_description = () => `Faites-vous accompagner par des experts pour accélérer votre apprentissage.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_pathname = () => `/accelerateurs/[slug]`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_title = () => `Accélérateurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_headline = () => `Le meilleur de Believemy, pour les plus exigeants ou les reconversions professionnelles.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerators_languageNotSupportedYet = () => `Nos accélérateurs sont disponibles uniquement en français pour le moment. Nous travaillons à les rendre disponibles dans d'autres langues pour vous offrir la meilleure expérience possible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_pathname = () => `/mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_meta_title = () => `Mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_meta_description = () => `Faites-vous accompagner personnellement par des professionnels de votre industrie.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_title = () => `Mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_headline = () => `Faites-vous accompagner personnellement par des professionnels de votre industrie.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_noMentorsFoundAlert = () => `Nous n'avons pas trouvé de mentor correspondant à vos critères. Pensez à enlever des filtres afin d'agrandir vos possibilités.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_filters_title = () => `Filtres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_filters_description = () => `Les domaines s'accumulent. Seuls les mentors ayant des compétences dans tous les domaines choisis sont affichés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentoring_filters_domains = () => `Domaines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_pathname = () => `/mentorat/[pseudo]`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_all = () => `Tout`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_marketing = () => `Marketing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_copywriting = () => `Copywriting`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_seo = () => `SEO`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_startup = () => `Startup`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_psychology = () => `Psychologie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_ecommerce = () => `Ecommerce`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_design = () => `Design`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const filter_domain_programming = () => `Programmation`


/**
 * @param {{ pseudo: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_pathname_generation = (params) => `/mentorat/${params.pseudo}`


/**
 * @param {{ fullName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_meta_title = (params) => `Prenez rendez-vous avec ${params.fullName}`


/**
 * @param {{ fullName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_meta_description = (params) => `Réservez un moment privilégié en toute confidentialité avec ${params.fullName} pour discuter de votre projet.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_domains = () => `Domaines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_presentation = () => `Présentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_recommendations = () => `Recommandations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentorprofile_no_recommendations = () => `Soyez le premier à recommander ce mentor : prenez un rendez-vous avec lui.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_priceLabel = () => `/ appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_free = () => `Gratuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_onlyOneLeftForThisPrice = () => `Plus qu'un appel à ce prix disponible.`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_onlyFewCallsAvailableAtThisPrice = (params) => `Plus que ${params.number} appels disponibles à ce prix.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_minutes = () => `minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_hours = () => `heures`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_onlyFewDaysAvailableAtThisPrice = (params) => `Plus que ${params.number} jours à ce prix.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_onlyOneDayAvailableAtThisPrice = () => `Plus qu'un jour à ce prix.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_reservation_success = () => `Booké !`


/**
 * @param {{ fullName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_reservation_success_message = (params) => `Votre appel avec ${params.fullName} a été réservé avec succès. Retrouvez-le dès maintenant sur votre tableau de bord.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_code_added = () => `Votre code vient d'être ajouté.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_code_invalid = () => `Le code que vous utilisez n'est plus valide.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_slotNotAvailable = () => `Il n'y a plus de créneaux disponibles sur ce jour pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_bookForFree = () => `Réservez gratuitement un appel avec votre mentor.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_cantBookWithThisMentor = () => `Vous n'avez pas droit aux réservations avec ce mentor.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_pleaseSelectDateAndHour = () => `Veuillez sélectionner une date et une heure`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_informationsChanged_success = () => `Vos informations ont bien été mises à jour.`


/**
 * @param {{ time: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_youHaveLessThan24HoursToEnjoyThisPrice = (params) => `Il ne vous reste plus que ${params.time} pour profiter de ce prix.`


/**
 * @param {{ minutesPerCall: NonNullable<unknown>, fullName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_bookDescription = (params) => `Appel de ${params.minutesPerCall} minutes par visioconférence ou par téléphone entre vous et ${params.fullName}`


/**
 * @param {{ minutesPerCall: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_minutes = (params) => `${params.minutesPerCall} minutes d'appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_hiddenFees = () => `Sans aucun frais supplémentaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_selection = () => `Mentor sélectionné par Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable = () => `Annulation possible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable_day = () => `Jusqu'à un jour avant votre appel`


/**
 * @param {{ days: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable_days = (params) => `Jusqu'à ${params.days} jours avant votre appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable_hour = () => `Jusqu'à une heure avant votre appel`


/**
 * @param {{ hours: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_cancellationAvailable_hours = (params) => `Jusqu'à ${params.hours} heures avant votre appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_freeCancellation = () => `Annulation gratuite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_fullRefund = () => `Remboursement intégral`


/**
 * @param {{ percent: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_partialRefund = (params) => `Remboursement de ${params.percent}% du total`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_modificationAvailable = () => `Modifiable gratuitement`


/**
 * @param {{ numberOfChanges: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_modificationAvailable_day = (params) => `Modifiable ${params.numberOfChanges} fois jusqu'à un jour avant votre appel`


/**
 * @param {{ numberOfChanges: NonNullable<unknown>, days: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_modificationAvailable_days = (params) => `Modifiable ${params.numberOfChanges} fois jusqu'à ${params.days} jours avant votre appel`


/**
 * @param {{ numberOfChanges: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_modificationAvailable_hour = (params) => `Modifiable ${params.numberOfChanges} fois jusqu'à une heure avant votre appel`


/**
 * @param {{ numberOfChanges: NonNullable<unknown>, hours: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_point_modificationAvailable_hours = (params) => `Modifiable ${params.numberOfChanges} fois jusqu'à ${params.hours} heures avant votre appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_modificationAvailableMaximumOneDayAfterDescription = () => `Maximum un jour de décallage`


/**
 * @param {{ days: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_book_modificationAvailableMaximumDaysAfterDescription = (params) => `Maximum ${params.days} jours de décallage`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_button_bookACall = () => `Réserver un appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_button_soon = () => `Bientôt disponible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_button_bookAFreeCall = () => `Réserver gratuitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_address_title = () => `Facturation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_address_updateQuestion = () => `Cette adresse n'est pas correcte ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_address_updateButton = () => `Mettre à jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_button_next = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_calendar_title = () => `Choisissez votre date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_hour_title = () => `Choisissez votre rendez-vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_payment_title = () => `Réservez votre appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_payment_description = () => `En passant votre commande, vous acceptez nos conditions générales de vente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_payment_read = () => `Lire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookNewCall_payment_free_description = () => `Cet appel vous est offert.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_mon = () => `Lun`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_tue = () => `Mar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_wed = () => `Mer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_thu = () => `Jeu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_fri = () => `Ven`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_sat = () => `Sam`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Calendar_week_sun = () => `Dim`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Checkout_button_label = () => `Finaliser`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_street = () => `Rue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_street_required = () => `Votre rue est requise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_option = () => `Rue (facultatif)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_zipCode = () => `Code postal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_zipCode_required = () => `Votre code postal est requis.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_city = () => `Ville`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_city_required = () => `Votre ville est requise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_country = () => `Pays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AF = () => `Afghanistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ZA = () => `Afrique du Sud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AL = () => `Albanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DZ = () => `Algérie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DE = () => `Allemagne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AD = () => `Andorre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AO = () => `Angola`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AI = () => `Anguilla`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AQ = () => `Antarctique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AG = () => `Antigua-et-Barbuda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SA = () => `Arabie saoudite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AR = () => `Argentine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AM = () => `Arménie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AW = () => `Aruba`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AU = () => `Australie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AT = () => `Autriche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AZ = () => `Azerbaïdjan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BS = () => `Bahamas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BH = () => `Bahreïn`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BD = () => `Bangladesh`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BB = () => `Barbade`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BE = () => `Belgique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BZ = () => `Belize`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BJ = () => `Bénin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BM = () => `Bermudes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BT = () => `Bhoutan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BY = () => `Biélorussie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BO = () => `Bolivie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BA = () => `Bosnie-Herzégovine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BW = () => `Botswana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BR = () => `Brésil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BN = () => `Brunéi Darussalam`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BG = () => `Bulgarie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BF = () => `Burkina Faso`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BI = () => `Burundi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KH = () => `Cambodge`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CM = () => `Cameroun`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CA = () => `Canada`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CV = () => `Cap-Vert`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CL = () => `Chili`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CN = () => `Chine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CY = () => `Chypre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CO = () => `Colombie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KM = () => `Comores`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CG = () => `Congo-Brazzaville`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CD = () => `Congo-Kinshasa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KR = () => `Corée du Sud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CR = () => `Costa Rica`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CI = () => `Côte d’Ivoire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HR = () => `Croatie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CW = () => `Curaçao`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DK = () => `Danemark`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DJ = () => `Djibouti`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DM = () => `Dominique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_EG = () => `Égypte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AE = () => `Émirats arabes unis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_EC = () => `Équateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ER = () => `Érythrée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ES = () => `Espagne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_EE = () => `Estonie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SZ = () => `Eswatini`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VA = () => `État de la Cité du Vatican`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_US = () => `États-Unis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ET = () => `Éthiopie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FJ = () => `Fidji`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FI = () => `Finlande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FR = () => `France`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GA = () => `Gabon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GM = () => `Gambie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GE = () => `Géorgie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GS = () => `Géorgie du Sud et îles Sandwich du Sud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GH = () => `Ghana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GI = () => `Gibraltar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GR = () => `Grèce`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GD = () => `Grenade`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GL = () => `Groenland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GP = () => `Guadeloupe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GU = () => `Guam`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GT = () => `Guatemala`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GG = () => `Guernesey`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GN = () => `Guinée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GQ = () => `Guinée équatoriale`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GW = () => `Guinée-Bissau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GY = () => `Guyana`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GF = () => `Guyane française`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HT = () => `Haïti`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HN = () => `Honduras`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HU = () => `Hongrie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BV = () => `Île Bouvet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AC = () => `Île de l’Ascension`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IM = () => `Île de Man`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_AX = () => `Îles Åland`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KY = () => `Îles Caïmans`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CK = () => `Îles Cook`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FO = () => `Îles Féroé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_FK = () => `Îles Malouines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PN = () => `Îles Pitcairn`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SB = () => `Îles Salomon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TC = () => `Îles Turques-et-Caïques`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VG = () => `Îles Vierges britanniques`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IN = () => `Inde`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ID = () => `Indonésie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IQ = () => `Irak`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IE = () => `Irlande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IS = () => `Islande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IL = () => `Israël`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IT = () => `Italie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_JM = () => `Jamaïque`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_JP = () => `Japon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_JE = () => `Jersey`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_JO = () => `Jordanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KZ = () => `Kazakhstan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KE = () => `Kenya`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KG = () => `Kirghizistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KI = () => `Kiribati`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_XK = () => `Kosovo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KW = () => `Koweït`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RE = () => `La Réunion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LA = () => `Laos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LS = () => `Lesotho`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LV = () => `Lettonie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LB = () => `Liban`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LR = () => `Libéria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LY = () => `Libye`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LI = () => `Liechtenstein`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LT = () => `Lituanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LU = () => `Luxembourg`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MK = () => `Macédoine du Nord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MG = () => `Madagascar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MY = () => `Malaisie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MW = () => `Malawi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MV = () => `Maldives`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ML = () => `Mali`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MT = () => `Malte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MA = () => `Maroc`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MQ = () => `Martinique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MU = () => `Maurice`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MR = () => `Mauritanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_YT = () => `Mayotte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MX = () => `Mexique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MD = () => `Moldavie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MC = () => `Monaco`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MN = () => `Mongolie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ME = () => `Monténégro`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MS = () => `Montserrat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MZ = () => `Mozambique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MM = () => `Myanmar (Birmanie)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NA = () => `Namibie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NR = () => `Nauru`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NP = () => `Népal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NI = () => `Nicaragua`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NE = () => `Niger`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NG = () => `Nigéria`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NU = () => `Niue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NO = () => `Norvège`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NC = () => `Nouvelle-Calédonie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NZ = () => `Nouvelle-Zélande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_OM = () => `Oman`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_UG = () => `Ouganda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_UZ = () => `Ouzbékistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PK = () => `Pakistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PA = () => `Panama`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PG = () => `Papouasie-Nouvelle-Guinée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PY = () => `Paraguay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_NL = () => `Pays-Bas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BQ = () => `Pays-Bas caribéens`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PE = () => `Pérou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PH = () => `Philippines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PL = () => `Pologne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PF = () => `Polynésie française`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PR = () => `Porto Rico`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PT = () => `Portugal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_QA = () => `Qatar`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_HK = () => `R.A.S. chinoise de Hong Kong`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MO = () => `R.A.S. chinoise de Macao`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CF = () => `République centrafricaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_DO = () => `République dominicaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RO = () => `Roumanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_GB = () => `Royaume-Uni`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RU = () => `Russie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RW = () => `Rwanda`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_EH = () => `Sahara occidental`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_BL = () => `Saint-Barthélemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_KN = () => `Saint-Christophe-et-Niévès`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SM = () => `Saint-Marin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_MF = () => `Saint-Martin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SX = () => `Saint-Martin (partie néerlandaise)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PM = () => `Saint-Pierre-et-Miquelon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VC = () => `Saint-Vincent-et-les-Grenadines`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SH = () => `Sainte-Hélène`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LC = () => `Sainte-Lucie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SV = () => `Salvador`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_WS = () => `Samoa`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ST = () => `Sao Tomé-et-Principe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SN = () => `Sénégal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_RS = () => `Serbie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SC = () => `Seychelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SL = () => `Sierra Leone`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SG = () => `Singapour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SK = () => `Slovaquie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SI = () => `Slovénie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SO = () => `Somalie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SS = () => `Soudan du Sud`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_LK = () => `Sri Lanka`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SE = () => `Suède`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CH = () => `Suisse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SR = () => `Suriname`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_SJ = () => `Svalbard et Jan Mayen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TJ = () => `Tadjikistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TW = () => `Taïwan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TZ = () => `Tanzanie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TD = () => `Tchad`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_CZ = () => `Tchéquie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TF = () => `Terres australes françaises`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_IO = () => `Territoire britannique de l’océan Indien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_PS = () => `Territoires palestiniens`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TH = () => `Thaïlande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TL = () => `Timor oriental`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TG = () => `Togo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TK = () => `Tokelau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TO = () => `Tonga`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TT = () => `Trinité-et-Tobago`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TA = () => `Tristan da Cunha`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TN = () => `Tunisie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TM = () => `Turkménistan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TR = () => `Turquie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_TV = () => `Tuvalu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_UA = () => `Ukraine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_UY = () => `Uruguay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VU = () => `Vanuatu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VE = () => `Venezuela`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_VN = () => `Vietnam`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_WF = () => `Wallis-et-Futuna`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_YE = () => `Yémen`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ZM = () => `Zambie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_selectedCountry_ZW = () => `Zimbabwe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_legalMentions = () => `Mentions légales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_privacy = () => `Confidentialité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_qualiopi = () => `Certificat Qualiopi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_qualiopi_description = () => `La certification qualité a été délivrée pour la catégorie d'actions « Actions de formation ».`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_accelerators = () => `Accélérateurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_accelerators_rocket = () => `Rocket`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_accelerators_newYear = () => `Belle Année`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_accelerators_founder = () => `Founder`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks = () => `Liens utiles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_becomingInstructor = () => `Devenir formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_becomingMentor = () => `Devenir mentor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_pricing = () => `Believemy Premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_articles = () => `Articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_courses = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_glossaries = () => `Glossaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_community = () => `Communauté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_mentoring = () => `Mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_events = () => `Événements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_podcast_conversationWith = () => `Podcast : Conservation With.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_usefulLinks_podcast_bookReviews = () => `Podcast : Revues de livres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_followUs = () => `Suivez-nous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_followUs_press = () => `Presse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Footer_weLoveThem = () => `On les adore`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_administrationPanel = () => `Administration`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_instructorPanel = () => `Panel de formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_mentorPanel = () => `Panel de mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_writerPanel = () => `Panel de rédacteur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_profile = () => `Mon profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_account = () => `Paramètres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_darkMode = () => `Eteindre la lumière`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_lightMode = () => `Allumer la lumière`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userDropdown_logout = () => `Déconnexion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_emptyResults = () => `Aucun résultat trouvé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_placeholder = () => `Rechercher sur Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_title = () => `Recherche rapide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_search_placeholder = () => `Rechercher...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_courses = () => `Résultats pour les formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_loadMore = () => `Voir plus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SpeedSearch_articles = () => `Résultats pour les articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_title = () => `Série`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_one_day = () => `Vous êtes actuellement sur une série d'un jour ! Terminez une session par jour pour augmenter votre série.`


/**
 * @param {{ days: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_x_days = (params) => `Vous êtes actuellement sur une série de ${params.days} jours ! Terminez une session par jour pour augmenter votre série.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_longest = () => `Record`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_days = () => `jours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_day = () => `jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_shields = () => `Boucliers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_shields_plural = () => `Boucliers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Streak_shields_singular = () => `Bouclier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fragments_title = () => `Fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fragments_description = () => `Les fragments sont gagnés en participant à la communauté ou en marquant de grandes étapes dans votre parcours.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fragments_showMyFragments = () => `Voir mes fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_title = () => `À propos des points d'expérience`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_description1 = () => `Les points d'expérience (XP) sont une manière de mesurer votre activité sur Believemy. Plus vous êtes actif, plus vous gagnez d'XP.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_description2 = () => `Le but est de vous encourager à progresser et à vous investir pour votre apprentissage.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_description3 = () => `Là où les fragments permettent de récompenser les actions ponctuelles, qui font avancer la communauté, les XP permettent de récompenser votre activité quotidienne sur la plateforme.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_title = () => `Détails des progressions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_description = () => `Vous souhaitez gagner des points d'expérience ? Voici comment sont attribués les XP :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_1 = () => `Compléter une session de formation : 10xp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_2 = () => `Réussir un quiz à 100% : 20xp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_3 = () => `Être la meilleure réponse à une question : 50xp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_modale_howToEarnXP_4 = () => `Décrocher une certification sur Believemy : 100xp`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_meta_title = () => `La boutique des fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_meta_description = () => `Bienvenue dans notre boutique des fragments : c'est ici qu'on passe à la caisse.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_title = () => `La Boutique des fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_headline = () => `C'est ici qu'on passe à la caisse. Heureusement, on prend aussi les fragments.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_explanation = () => `C'est quoi un fragment ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_title = () => `C'est quoi un fragment ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_description1 = () => `Les fragments sont des denrées très précieuses : ils sont à la base de l'économie de Believemy. Ils ont été inventés pour récompenser les âmes généreuses : que vous aimiez écrire des articles ou que vous appréciez aider les autres en répondant à leurs questions sur une formation, vous gagnerez des fragments.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_description2 = () => `Vous gagnerez également des fragments à chaque fois que vous obtiendrez une certification sur Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_description3 = () => `Comme nous aimons faire les choses en profondeur et que nous voulons vous récompenser pour votre implication, nous avons décidé de créer une boutique où vous pourrez échanger vos fragments durement gagnés contre des récompenses.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_description4 = () => `Cliquez ici pour écrire un article et gagner des fragments.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_title = () => `Comment gagner des fragments ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_description = () => `Vous souhaitez gagner des fragments ? C'est très simple. Voici comment il est possible de miner ces derniers :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_1 = () => `Un mois d'abonnement à Believemy Premium : 25 fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_2 = () => `En créant un article : 100 fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_howToEarnFragments_3 = () => `Décrocher une certification sur Believemy : 100 fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_alert_openSinceLittleTime = () => `La Boutique est ouverte depuis seulement quelque temps. Tout n'est pas parfait, mais l'essentiel, c'est que vous allez pouvoir vous faire plaisir !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_title = () => `À propos des soirées spéciales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_description1 = () => `Believemy n'appartient pas seulement à l'équipe qui le fait vivre, mais aussi à vous, la communauté. C'est pourquoi nous organisons régulièrement des soirées spéciales pour vous rencontrer, échanger et partager des moments inoubliables.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_description2 = () => `La nature de ces soirées n'est pas figée : préparez-vous à être surpris !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_description3 = () => `Pour obtenir une invitation, il n'y a pas de secret : il faut être un membre actif et engagé de la communauté. Personne ne sait comment les invitations sont distribuées, mais une chose est sûre : plus vous êtes actif, plus vous avez de chances d'être invité.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_title = () => `Quelques détails`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_description = () => `Ces invitations sont très spéciales et tombent quand on s'y attend le moins. Si vous avez la chance d'en recevoir une, vous serez contacté par mail. Cependant, voici quelques pistes qui vous permettront d'augmenter vos chances :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_1 = () => `Être un membre actif de la communauté sur Discord ou sur le site est essentiel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_2 = () => `Écrire des articles sur Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_3 = () => `Être formateur sur Believemy peut vous aider`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_4 = () => `Être mentor sur Believemy peut y contribuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_5 = () => `Être dans un accélérateur de Believemy peut vous donner un avantage`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_modale_invitation_details_6 = () => `Être abonné à Believemy Premium peut vous donner un avantage`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_shield_title = () => `Bouclier de protection`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_shield_description = () => `Protégez votre série de jours contre les imprévus.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_name = () => `Fragments`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_button_buy = () => `Acheter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_pen_title = () => `Un stylo à bille "Believemy Origin"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_pen_description = () => `Recevez chez vous un stylo à bille de la collection Origin. Superbe pour prendre des notes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_premium_title = () => `Un mois d'abonnement à Believemy Premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_premium_description = () => `Recevez un mois d'abonnement à Believemy Premium.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_booknote_title = () => `Un carnet de notes "Believemy Origin"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_booknote_description = () => `Recevez chez vous un carnet de notes de la collection Origin, blanc ou noir.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_usb_title = () => `Une clé USB 2.0 "Believemy Origin"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_usb_description = () => `Recevez chez vous une clé USB de la collection Origin, parfait pour stocker vos données à l'abri du big brother.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_bag_title = () => `Un tote bag "Believemy Origin"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_bag_description = () => `Recevez chez vous un tote bag la collection Origin, pratique pour acheter vos capscules de café.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_mug_title = () => `Un mug "Believemy Origin"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_mug_description = () => `Recevez chez vous un mug de la collection Origin, idéal pour le thé ou le café.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_invitation_title = () => `Une invitation spéciale à une soirée inoubliable`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_invitation_description = () => `Recevez une invitation pour une soirée inoubliable organisée par Believemy, avec l'équipe et d'autres membres de la communauté.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_invitation_forTheMostLoyal = () => `Pour les plus fidèles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fragments_shop_invitation_button = () => `En savoir plus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upload_button_label = () => `Choisir un fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_meta_title = () => `Panel de rédacteur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_meta_description = () => `Écrivez des articles pour Believemy, partagez avec le monde et gagnez des fragments.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_title = () => `Panel de rédacteur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_headline = () => `Écrivez des articles pour Believemy, partagez avec le monde et gagnez des fragments.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_nav_home = () => `Accueil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_nav_newArticle = () => `Créer un article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_nav_files = () => `Héberger`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_drafts_title = () => `Mes brouillons`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_drafts_empty = () => `Commencez par rédiger un article.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_articles_title = () => `Mes articles publiés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_articles_empty = () => `Vous n'avez pas encore publié d'article.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_newArticle_title = () => `Créer un article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_thumbnail_title = () => `Miniature`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_thumbnail_description = () => `La couverture sur un article est l'élément le plus important. Soignez bien cette première impression, elle fera connaître le succès à votre article.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_aside_description1 = () => `Chaque article est ensuite validé par notre équipe de modération. Vous serez notifié par mail lorsque votre article sera publié.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_aside_description2 = () => `Si vous avez besoin d'un conseil ou d'une aide, n'hésitez pas à nous contacter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_aside_description3 = () => `L'utilisation d'une intelligence artificielle pour la rédaction de vos articles est interdite.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_aside_files = () => `Hébergez vos fichiers ici`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_title_label = () => `Titre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_title_placeholder = () => `Le titre de votre article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_description_label = () => `Description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_description_placeholder = () => `Une description courte de votre article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_content_label = () => `Contenu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_content_placeholder = () => `Rédigez votre article ici`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_button_submit = () => `Enregistrer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_thumbnail_type_error = () => `Veuillez ajouter une image valide (png / jpg / jpeg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_thumbnail_empty_error = () => `Veuillez ajouter une image`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_title_empty_error = () => `Veuillez ajouter un titre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_description_empty_error = () => `Veuillez ajouter une description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_content_empty_error = () => `Veuillez ajouter un contenu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_unknown_error = () => `Une erreur inconnue s'est produite. Veuillez réessayer plus tard.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_added = () => `Votre article a été ajouté avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_email_not_verified = () => `Vous devez vérifier votre adresse mail pour pouvoir écrire des articles.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_files_meta_title = () => `Héberger des fichiers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_files_meta_description = () => `Hébergez des fichiers pour vos articles sur Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_myDocuments = () => `Mes documents`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_title = () => `Héberger des fichiers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_headline = () => `Hébergez des fichiers pour vos articles sur Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_alert_usage = () => `Les fichiers hébergés ici sont destinés à être utilisés dans vos articles. Une infraction à cette règle peut entraîner la suppression de votre compte.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_copied = () => `Copié !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_linkToYourUpload = () => `Lien vers votre fichier : `


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_uploadAnotherDocument = () => `Envoyer un autre document`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_button_add_upload = () => `Ajouter un fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Files_noDocuments = () => `Vous n'avez pas encore de document.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_meta_title = () => `Panel de mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_meta_description = () => `Gérez vos appels, vos disponibilités et vos informations personnelles comme mentor.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_title = () => `Panel de mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_headline = () => `Gérez vos appels, vos disponibilités et vos informations personnelles comme mentor.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_nav_dashboard = () => `Tableau de bord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_nav_calls = () => `Mes appels`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_nav_codes = () => `Mes codes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_title = () => `Réservations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_reservation_period = () => `Les réservations peuvent se faire :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_reservation_period_indefinitely = () => `Indéfiniment dans le futur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_reservation_period_30days = () => `30 jours à l'avance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_reservation_period_14days = () => `14 jours à l'avance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_error_required = () => `Ce champ est requis.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_monday = () => `Lundi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_tuesday = () => `Mardi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_wednesday = () => `Mercredi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_thursday = () => `Jeudi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_friday = () => `Vendredi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_saturday = () => `Samedi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_sunday = () => `Dimanche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_to = () => `à`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_not_available = () => `Non disponible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_time_before_reservation = () => `Laisser un peu de temps avant ou après un événement :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_before = () => `Avant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_no = () => `Non`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_yes = () => `Oui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_after = () => `Après`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_5_minutes = () => `5 minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_10_minutes = () => `10 minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_15_minutes = () => `15 minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_20_minutes = () => `20 minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_25_minutes = () => `25 minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_30_minutes = () => `30 minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_45_minutes = () => `45 minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_1_hour = () => `1 heure`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_1_hour_30_minutes = () => `1 heure 30`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_2_hours = () => `2 heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_2_hours_30_minutes = () => `2 heures 30`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_duration_3_hours = () => `3 heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_timezone = () => `Fuseau horaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_minimum_hours_of_notice = () => `Nombre d'heures minimums de préavis avant un nouveau rendez-vous :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_minimum_hours_of_notice_placeholder = () => `Exemple : 24 pour 24 heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_open_reservations = () => `Ouvrir les réservations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_open_reservations_description = () => `Si vous ouvrez vos réservations sans avoir connecté de calendrier, vous devrez gérer vos disponibilités manuellement vos rendez-vous n'étant pas synchronisés avec Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_message = () => `Les valeurs définies ci-dessus ne modifient que les futurs appels réservés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_button_save = () => `Enregistrer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_google_calendar_title = () => `Calendriers`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_google_calendar_description = () => `Connectez votre calendrier Google pour synchroniser vos disponibilités avec Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_google_calendar_button_connect = () => `Connecter avec`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_google_calendar_button_disconnect = () => `Déconnecter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_title = () => `Informations personnelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_titles = () => `Titres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_titles_placeholder = () => `Exemple : CEO de la société X, consultant chez Y, etc.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_presentation = () => `Présentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_presentation_placeholder = () => `Quelques mots sur vous, votre parcours, vos expériences, les langues que vous parlez, etc.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_personalizedLink = () => `Lien personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_personalizedLink_placeholder = () => `Exemple : prenom.nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_domains = () => `Domaines de compétences`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_price = () => `Coût d'un appel avec vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_price_placeholder = () => `Exemple : 50`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_numberOfMinutesPerCall = () => `Nombre de minutes par appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_informations_numberOfMinutesPerCall_placeholder = () => `Exemple : 30 pour 30 minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_branding_title = () => `Branding`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_branding_portrait_title = () => `Portrait`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_branding_portrait_description = () => `Ajoutez un portrait pour que les utilisateurs puissent vous reconnaître.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_title = () => `Garanties & remboursements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_canBeCancelled = () => `Un rendez-vous peut être annulé :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_minimumHoursToCancel = () => `Nombre d'heures minimum nécessaires avant un appel pour l'annuler :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_minimumHoursToCancel_placeholder = () => `Exemple : 24 pour 24 heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_refundRate = () => `Taux de remboursement d'un appel qui a été annulé :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_refundRate_placeholder = () => `Exemple : 50 pour 50%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_canBeMoved = () => `Un rendez-vous peut être déplacé :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_minimumHoursToMove = () => `Nombre d'heures minimum nécessaires avant un appel pour le déplacer :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_minimumHoursToMove_placeholder = () => `Exemple : 24 pour 24 heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_numberOfDaysAfterInitial = () => `Nombre de jours maximum entre le rendez-vous original et le nouveau rendez-vous :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_numberOfDaysAfterInitial_placeholder = () => `Exemple : 7 pour 7 jours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_maximumChangeCall = () => `Nombre de fois qu'un rendez-vous peut être déplacé :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_guarantees_maximumChangeCall_placeholder = () => `Exemple : 1 pour 1 fois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_revenue_title = () => `Revenus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_revenue_see_details = () => `Détails`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_referent_title = () => `Référent personnel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_referent_description = () => `En cas d'urgence ou de besoin précis, votre référent personnel est là pour vous accompagner.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_referent_yourReferent = () => `Votre référent :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_personal_referent_contact = () => `Contacter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_informations_updated = () => `Informations modifiées avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_maximum_change_call_invalid = () => `Le nombre de changement de rendez-vous doit être supérieur ou égal à 1 si vous les acceptez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForMonday_egual = () => `Une de vos disponibilité de début et de fin du lundi est invalide : elles ne peuvent pas être identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForMonday_firstGreaterThandSecond = () => `Une de vos disponibilité de début et de fin du lundi est invalide : la fin doit être après le début.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForTuesday_egual = () => `Une de vos disponibilité de début et de fin du mardi est invalide : elles ne peuvent pas être identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForTuesday_firstGreaterThandSecond = () => `Une de vos disponibilité de début et de fin du mardi est invalide : la fin doit être après le début.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForWednesday_egual = () => `Une de vos disponibilité de début et de fin du mercredi est invalide : elles ne peuvent pas être identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForWednesday_firstGreaterThandSecond = () => `Une de vos disponibilité de début et de fin du mercredi est invalide : la fin doit être après le début.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForThursday_egual = () => `Une de vos disponibilité de début et de fin du jeudi est invalide : elles ne peuvent pas être identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForThursday_firstGreaterThandSecond = () => `Une de vos disponibilité de début et de fin du jeudi est invalide : la fin doit être après le début.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForFriday_egual = () => `Une de vos disponibilité de début et de fin du vendredi est invalide : elles ne peuvent pas être identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForFriday_firstGreaterThandSecond = () => `Une de vos disponibilité de début et de fin du vendredi est invalide : la fin doit être après le début.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForSaturday_egual = () => `Une de vos disponibilité de début et de fin du samedi est invalide : elles ne peuvent pas être identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForSaturday_firstGreaterThandSecond = () => `Une de vos disponibilité de début et de fin du samedi est invalide : la fin doit être après le début.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForSunday_egual = () => `Une de vos disponibilité de début et de fin du dimanche est invalide : elles ne peuvent pas être identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_reservationInvalidForSunday_firstGreaterThandSecond = () => `Une de vos disponibilité de début et de fin du dimanche est invalide : la fin doit être après le début.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_price_invalid = () => `Le prix de votre coupon n'est pas un nombre. Veuillez modifier le type du coupon ou définir un prix.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_price_negative = () => `Le prix de votre coupon ne peut pas être négatif.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_maxUses_invalid = () => `Le nombre d'utilisations maximum de votre coupon n'est pas un nombre ou est inférieur à 1.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_limitDate_invalid = () => `La date limite de votre coupon est avant la date actuelle.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_name_maxLenght_invalid = () => `Le nom de votre coupon ne peut pas dépasser 130 caractères.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_created = () => `Code créé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_google_disconnect_askConfirmation = () => `Êtes-vous sûr de vouloir déconnecter votre calendrier Google ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_google_disconnect_success = () => `Déconnexion réussie.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_google_connected = () => `Calendrier Google connecté.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_google_error = () => `Une erreur est survenue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_archived = () => `Code archivé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_code_unarchived = () => `Code désarchivé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const thumbnail_type_error = () => `Veuillez ajouter une image valide (png / jpg / jpeg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_picture_error = () => `Une erreur est survenue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_picture_updated = () => `Portrait mis à jour avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_stripe_account_verified = () => `Informations bancaires validées.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_coming_calls_title = () => `Appels à venir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_coming_calls_empty = () => `Aucun appel à venir.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_toEnd_calls_title = () => `Appels à terminer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_past_calls_title = () => ` Appels passés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_past_calls_empty = () => `Aucun appel passé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_confirm_paid = () => `Êtes-vous sûr de vouloir marquer cet appel comme réglé ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_paid_success = () => `Appel marqué comme réglé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_with = () => `Avec`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_yourRevenue = () => `Votre revenu :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_revenue = () => `Revenu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_inclVAT = () => `TTC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_exclVAT = () => `HT`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_codeUsed = () => `Code utilisé :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_paid_at = () => `Paiement effectué le`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_payment_pending = () => `Paiement en attente.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_hide_details = () => `Masquer les détails`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_show_details = () => `Voir les détails`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_mark_paid = () => `Déclarer comme payé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_check_call = () => `Voir cet appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_total_paid = () => `Total payé :`


/**
 * @param {{ percent: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_banking_fees_percent = (params) => `Frais bancaires (${params.percent} %)`


/**
 * @param {{ percent: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_commission_believemy = (params) => `Commission Believemy (${params.percent} %) :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_commission_bankingFees = () => `Frais bancaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_original_revenue = () => `Votre revenu d'origine :`


/**
 * @param {{ percent: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_revenue_after_refund = (params) => `Votre revenu définitif après remboursement (${params.percent} %) :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_refund_amount = () => `Remboursement perçu par le correspondant :`


/**
 * @param {{ 'percent %': NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_refund_by_you = (params) => `Remboursement de votre part (${params['percent %']} :`


/**
 * @param {{ 'percent %': NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_refund_by_believemy = (params) => `Remboursement de la part de Believemt (${params['percent %']} :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_final_revenue = () => `Votre revenu :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Call_cancelled = () => `Annulé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_title = () => `Mes codes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_create = () => `Créer un code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_name = () => `Nom du code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_name_placeholder = () => `Exemple : BELIEVEMY`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_maxUses = () => `Nombre d'utilisations maximum`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_maxUses_placeholder = () => `Exemple : 10 pour 10 utilisations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_type = () => `Type de coupon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_type_price = () => `Prix personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_type_free = () => `Gratuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_price = () => `Prix personnalisé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_price_placeholder = () => `Exemple : 50 pour 50 €`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_limitDate = () => `Définir une date limite d'utilisation ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_limitDate_placeholder = () => `Laisser vide pour ne pas définir de date limite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_form_button_create = () => `Créer un code`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_active = () => `Actifs en ce moment`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_expired = () => `Code expiré`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_valid = () => `Code valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_free = () => `Gratuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_valid_until = () => `Valide jusqu'au`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clipboard_copied = () => `Copié !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_copy = () => `Copier le lien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_disable = () => `Désactiver`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_enable = () => `Activer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_disabled = () => `Inactifs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_empty = () => `Vous n'avez pas encore de code.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_aside_title = () => `Qu'est-ce qu'un code ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_aside_description1 = () => `Un code est un moyen de récompenser vos clients ou de leur offrir un accès gratuit à vos services. Vous pouvez définir un prix personnalisé ou offrir un accès gratuit.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mentor_dashboard_codes_aside_description2 = () => `Les codes peuvent être utilisés un nombre limité de fois et peuvent être désactivés à tout moment.`


/**
 * @param {{ title: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_meta_title = (params) => `Editer "${params.title}"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_meta_description = () => `Editez votre article sur Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_form_description = () => `Cet article sera visible par le monde entier une fois publié et pourra être lu dans presque toutes les langues du monde entier.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_updated_successfully = () => `Article modifié avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_language_required = () => `Veuillez sélectionner une langue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_translation_for_this_language_already_exists = () => `Une traduction pour cette langue existe déjà. Veuillez la modifier directement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_title_required = () => `Veuillez ajouter un titre.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_created_successfully = () => `Traduction ajoutée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_error_occured = () => `Une erreur est survenue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_thumbnail_updated = () => `Miniature mise à jour avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_send_article = () => `Vous allez soumettre cet article à l'équipe de validation. Aucune modification ne sera possible. Voulez-vous continuer la publication de votre article ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_sent_successfully = () => `Article envoyé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const please_verify_your_email = () => `Veuillez vérifier votre compte.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_about_this_article = () => `Informations sur l'article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_createdAt = () => `Créé le :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_updatedAt = () => `Mis à jour le :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_translations = () => `Traductions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_title = () => `Titre de l'article dans cette langue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_description = () => `Description de l'article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_content = () => `Contenu de l'article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_slug = () => `Slug`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_cancel_edition = () => `Voulez-vous vraiment fermer les modifications ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_close = () => `Fermer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_button = () => `Sauvegarder`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_waiting_for_validation = (params) => `Envoyé pour validation le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_edit_link = () => `Modifier cette traduction`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_send_validation = () => `Envoyer pour validation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_badge_online = () => `En ligne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_badge_waiting_validation = () => `En attente de validation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_badge_writing = () => `En rédaction`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_author = (params) => `Par ${params.fullname}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_look_online = () => `Voir en ligne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_previsualisation = () => `Prévisualiser`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_no_translations = () => `Aucune traduction n'est disponible pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_new_translation = () => `Ajouter une nouvelle traduction`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_translation_language = () => `Langue de la traduction`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_select_language = () => `Sélectionnez une langue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_translation_title = () => `Titre de l'article dans cette langue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_create_translation_button = () => `Créer une traduction`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_translation_button = () => `Ajouter une traduction`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_waiting_title = () => `En attente de validation`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ArticleDate_updated_at = (params) => `Mis à jour le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_categories = () => `Catégories`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_category = () => `Catégorie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_developers = () => `Développement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_designers = () => `Design`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_marketers = () => `Marketing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_entrepreneurs = () => `Entrepreneuriat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const translateCategories_other = () => `Autres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CreatorCard_link_to_profile = () => `Voir son profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const article_loading = () => `Chargement...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_title = () => `Commentaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_shouldBeAuthToComment = () => `Vous devez être connecté pour commenter cet article :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_shouldBeAuthToComment_login = () => `Se connecter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_shouldBeAuthToComment_or = () => `ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comments_shouldBeAuthToComment_register = () => `s'inscrire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddComment_failedToAddComment = () => `Impossible d'ajouter votre commentaire. Veuillez réessayer plus tard.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddComment_commentAddedSuccessfully = () => `Commentaire ajouté avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddComment_cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddComment_submit = () => `Envoyer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Comment_reply = () => `Répondre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_default_title = () => `Essayez gratuitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_default_description = () => `Que vous essayiez de scaler votre start-up, de créer votre premier site internet ou de vous reconvertir en tant que développeur, Believemy est votre nouvelle maison. Rejoignez-nous, évoluez et construisons ensemble votre projet.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_default_button = () => `Essayer gratuitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_join_movement_title = () => `Rejoignez le mouvement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_join_movement_description_1 = () => `Envie de nous rejoindre ? C'est simple, il vous suffit de nous contacter par email en cliquant sur le bouton ci-dessous, et de nous en dire le plus possible sur la formation que vous souhaiteriez créer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_join_movement_description_2 = () => `Nous n'acceptons que les formations de qualité, et nous nous réservons le droit de refuser une formation si nous la jugeons inadaptée. Un formateur Believemy est un réel partenaire de confiance sur le long terme, chaque proposition est donc étudiée avec soin.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_join_movement_button = () => `Proposer une formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_mentoring_title = () => `Rejoignez le mouvement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_mentoring_description_1 = () => `Envie de nous rejoindre ? C'est simple, il vous suffit de nous contacter par email en cliquant sur le bouton ci-dessous, et de nous en dire le plus possible sur vous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_mentoring_description_2 = () => `Nous n'acceptons que les mentors qui peuvent réellement apporter une différence. Ceci par son parcours, sa façon de penser, d'être ou même par ses capacités. Un mentor Believemy est un réel partenaire de confiance sur le long terme, chaque proposition est donc étudiée avec soin.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_mentoring_button = () => `Devenir mentor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_title = () => `Rejoignez l'alternance par Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_description_1 = () => `On est prêt ? C'est très simple : il vous suffit de cliquer sur le bouton ci-dessous et de suivre les étapes sur la page suivante.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_description_2 = () => `Notre équipe admission est disponible à tout moment pour vous aider et pour répondre à vos questions. N'hésitez pas !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_button_1 = () => `L'alternance, c'est parti !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EndCTA_apprenticeship_button_2 = () => `Être recontacté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_badge_validated_but_not_online = () => `Validé mais temporairement hors-ligne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_validated_but_not_online_title = () => `Validé(s) mais hors-ligne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_update_video = () => `Vidéo (exemple : YouTube)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_NOT_AUTHOR = () => `Vous n'êtes pas l'auteur de cet article.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_categories_title = () => `Catégories`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_categories_description = () => `Chaque catégorie doit avoir un identifiant en anglais.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_input_placeholder = () => `Créer une nouvelle catégorie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_remove_category = () => `Retirer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_add_category = () => `Ajouter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_error_addCategoryName = () => `Veuillez entrer un nom de catégorie.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_error_reservedName = () => `Ce nom de catégorie est réservé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_categories_updated = () => `Catégories mises à jour.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_meta_title = () => `Panel de formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_meta_description = () => `Gérez vos formations, vos apprenants et vos informations personnelles comme formateur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_title = () => `Panel de formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_headline = () => `Gérez vos formations, vos apprenants et vos informations personnelles comme formateur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_courses = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_learners = () => `Apprenants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_statistics_title = () => `Croissance de votre influence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_mainChart_revenues = () => `Revenus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_mainChart_learners = () => `Apprenants actifs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_mainChart_minutesConsumed = () => `Minutes consommées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_created = () => `Formation ajoutée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_no_courses_title = () => `Créez votre première formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_no_courses_description = () => `Vous souhaitez partager vos connaissances ? Créez votre formation sur Believemy !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_add_course_button = () => `Créer une formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_aside_statistics_title = () => `Statistiques`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_numberOfStudents = (params) => `${params.count} apprenants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_one_student = () => `1 apprenant`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_numberOfCourses = (params) => `${params.count} formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_one_course = () => `1 formation`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_lifetimeRevenues = (params) => `${params.count} € au total`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_lifetimeMins = (params) => `${params.count} minutes consommées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_aside_coming_revenues_title = () => `Paiements à venir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_students_title = () => `Apprenants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_students_followed_courses = () => `Formations suivies`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_students_no_students = () => `Vous n'avez pas encore d'apprenant.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_title = () => `Titre de la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_title_placeholder = () => `Exemple : Devenez un expert en marketing digital`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_title_required = () => `Veuillez ajouter un titre.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_subtitle = () => `Sous-titre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_subtitle_placeholder = () => `Exemple : Apprenez à maîtriser les réseaux sociaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_subtitle_required = () => `Veuillez ajouter un sous-titre.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_description = () => `Description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_prerequisites = () => `Prérequis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_new_course_button = () => `Créer une formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_updated = () => `Formation modifiée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_sociale_type_error = () => `Veuillez ajouter une image valide (png / jpg / jpeg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_error_occured = () => `Une erreur est survenue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_updated_successfully = () => `Informations modifiées avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_storyart_type_error = () => `Veuillez ajouter une image valide (png / jpg / jpeg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_logo_type_error = () => `Veuillez ajouter une image valide (png / jpg / jpeg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_video_required = () => `Veuillez ajouter une vidéo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_video_delete_error = () => `Une erreur est survenue en supprimant votre ancienne vidéo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_course_sended_for_validation = () => `Votre formation a été envoyée en validation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_presentation = () => `Présentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_summary = () => `Plan`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_settings = () => `Paramètres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_sendForValidation = () => `Envoyer en validation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_nav_preview = () => `Prévisualisation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation = () => `Présentation de la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_title = () => `Titre de la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_title_placeholder = () => `Exemple : Devenez un expert en marketing digital`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_title_required = () => `Veuillez ajouter un titre.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_subtitle = () => `Sous-titre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_subtitle_placeholder = () => `Exemple : Apprenez à maîtriser les réseaux sociaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_subtitle_required = () => `Veuillez ajouter un sous-titre.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_description = () => `Description`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_description_placeholder = () => `Rédigez une description de votre formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_prerequisites = () => `Prérequis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_presentation_form_prerequisites_placeholder = () => `Exemple : Aucun prérequis nécessaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_button_save = () => `Enregistrer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_title = () => `Apparence de la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_logo_title = () => `Logo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_logo_description = () => `Le logo est une image qui permet d'identifier votre formation. Un peu comme une application se différencie par son icône.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_thumbnail_title = () => `Miniature`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_thumbnail_description = () => `La miniature est la première image que les apprenants verront de votre formation. Elle doit être accrocheuse et donner envie d'en savoir plus.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_storyart_title = () => `Story Art`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_storyart_description = () => `Le Story Art est une image qui permet de donner un aperçu de votre formation. C'est un peu comme la jaquette d'un livre.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_video_in_process = () => `Vidéo en cours de traitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_video_title = () => `Vidéo de présentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_branding_video_description = () => `La vidéo de présentation est un moyen de montrer votre formation en action. C'est un peu comme une bande-annonce de film.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_summary_title = () => `Plan de la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_summary_description = () => `Depuis cet espace il est possible d'ajouter du contenu sur votre formation, de le modifier et de le supprimer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_title_empty = () => `Le titre ne peut pas être vide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_part_created = () => `Partie créée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_quiz_created = () => `Quiz créé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_formation_empty = () => `Veuillez sélectionner une formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_formation_added_with_program = () => `Formation ajoutée avec succès avec l'accélérateur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_title = () => `Ajouter une partie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_title_placeholder = () => `Titre de la partie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_add_content_button = () => `Ajouter la partie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_quiz_title = () => `Titre du quiz`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_quiz_description_placeholder = () => `Si vous ne définissez pas de description, nous ajouterons une description générique.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_add_quiz_button = () => `Ajouter le quiz`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_formation_select = () => `Selectionnez une formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_add_formation_button = () => `Ajouter la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_select_type = () => `Quel type de partie voulez-vous ajouter ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_type_content_title = () => `Contenu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_type_content_description = () => `Ajoutez du contenu à votre formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_type_quiz_title = () => `Quiz`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_type_quiz_description = () => `Ajoutez un quiz à votre formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_next = () => `Suivant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddPart_add_part = () => `Ajouter une partie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_summary_empty = () => `Créez votre première parti ajouter votre première session sur votre formation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_title = () => `Paramètres de la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_description = () => `L'ensemble des paramètres de votre formation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_duration = () => `Durée de votre formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_duration_placeholder = () => `Exemple : 10 heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_language = () => `Langue de cette formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_language_placeholder = () => `Exemple : Français`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_subtitles = () => `Sous-titres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_subtitles_placeholder = () => `Exemple : Anglais, Espagnol`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_has_4Kresolution = () => `Votre formation est-elle en 4K ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_has_4Kresolution_yes = () => `Oui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_has_4Kresolution_no = () => `Non`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_is_up_to_date = (params) => `Votre formation est-elle à jour au ${params.date} ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_is_up_to_date_yes = () => `Oui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_is_up_to_date_no = () => `Non`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_parameters_instructor = () => `Formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_title = () => `Envoyer en validation`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description = (params) => `Formation envoyée en validation le ${params.date}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description2 = () => `Avant d'envoyer votre formation, il est impératif de vérifier que vous respectez bien nos consignes en matière de qualité :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description3 = () => `Les vidéos doivent être disponible au moins en full HD ;`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description4 = () => `Les codes sources doivent être disponibles dans le cas d'une formation de développement ;`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_send_for_evaluation_description5 = () => `La qualité audio doit être excellente.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_button_send_for_evaluation = () => `Envoyer en validation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_title_required = () => `Le titre ne peut pas être vide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_updated = () => `Partie modifiée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_delete_ask_confirmation = () => `En supprimant cette partie, vous allez supprimer également chacune des sessions de cette dernière. Voulez-vous vraiment supprimer la partie ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_deleted = () => `Partie supprimée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_generic_error = () => `Une erreur est survenue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_summary_placeholder = () => `Qu'allons-nous apprendre dans cette partie ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_description_for_quiz_placeholder = () => `Si vous ne définissez pas de description, nous ajouterons une description générique.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_should_plan_this_part = () => `Planifier la sortie ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_yes = () => `Oui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_no = () => `Non`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_should_plan_this_part_for_week = () => `Semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_week_example = () => `Exemple : 1 pour la première semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_module = () => `Module`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_module_placeholder = () => `Ne rien ajouter si aucun module`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_week = () => `Semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_should_be_included_in_accelerators = () => `Inclure dans les accélérateurs ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_delete = () => `Supprimer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_takeOff = () => `Retirer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_putOnline = () => `Publier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_restrict_attempts = () => `Bloquer à une tentative`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_without_restrictions = () => `Laisser des tentatives illimitées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_save = () => `Enregistrer`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_number = (params) => `Partie ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Part_notPublished = () => `Non publié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_title_empty = () => `Le titre ne peut pas être vide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_should_have_one_correct_answer_for_quiz = () => `Vous devez avoir au moins une réponse correcte.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_quiz = () => `Ajouter une question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_session = () => `Ajouter une session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_question_placeholder = () => `Titre de la question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_quiz_answers = () => `Réponses`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_answer_number = (params) => `Réponse ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_explanation_placeholder = () => `Pourquoi cette réponse est-elle correcte ou incorrecte ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_you_can_add_other_answers = () => `Vous pouvez ajouter autant de réponses que vous le souhaitez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_another_answer = () => `Ajouter une autre réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_question_button = () => `Ajouter la question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_title_placeholder = () => `Titre de la session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AddSession_add_session_button = () => `Ajouter la session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const instructor_page_description_required = () => `Une description est requise.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_title_cannot_be_empty = () => `Le titre ne peut pas être vide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_updated = () => `Session modifiée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_at_least_one_correct_answer = () => `Vous devez avoir au moins une réponse correcte.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_error_occured = () => `Une erreur est survenue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_ask_confirm_deletion = () => `Voulez-vous vraiment supprimer cette session ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_deleted = () => `Session supprimée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_error_occured_deleting_video = () => `Une erreur est survenue en supprimant la vidéo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_no_video_selected = () => `Veuillez ajouter une vidéo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_ask_confirm_deletion_video = () => `Voulez-vous vraiment supprimer cette vidéo ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_video_deleted = () => `Vidéo supprimée avec succès.`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_question_number = (params) => `Question ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_free_access = () => `Accès offert`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_offline = () => `Hors-ligne`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_question_title = () => `Titre de la question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_question_title_placeholder = () => `Si votre session n'a pas de contenu, vous pouvez laisser ce champ vide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_question_answers = () => `Réponses`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_answer_number = (params) => `Réponse ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_explanation_placeholder = () => `Pourquoi cette réponse est-elle correcte ou incorrecte ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_you_can_add_other_answers = () => `Vous pouvez ajouter autant de réponses que vous le souhaitez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_add_another_answer = () => `Ajouter une autre réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_delete = () => `Supprimer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_make_quiz_not_free = () => `Rendre le quiz payant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_make_quiz_free = () => `Rendre le quiz gratuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_takeOff = () => `Retirer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_putOnline = () => `Publier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_save = () => `Enregistrer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_title = () => `Titre de la session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_content = () => `Contenu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_content_placeholder = () => `Si votre session n'a pas de contenu, vous pouvez laisser ce champ vide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_video_in_process = () => `Vidéo en cours de traitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_video = () => `Vidéo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_update = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_add = () => `Ajouter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_make_session_not_free = () => `Rendre payant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Session_make_session_free = () => `Rendre gratuit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_title = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_headline = () => `Apprenez de nouvelles compétences avec nos formations primées.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_meta_title = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_meta_description = () => `Apprenez de nouvelles compétences avec nos formations primées.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_languageNotSupportedYet = () => `Nos formations sont disponibles uniquement en français pour le moment. Nous travaillons à les rendre disponibles dans d'autres langues pour vous offrir la meilleure expérience possible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_courseNotInYourLanguage = () => `Langue différente`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCourses = () => `Nous n'avons pas encore de formation dans cette catégorie, elles arrivent très bientôt.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCoursesMessage = () => `Vous êtes formateur ou vous connaissez un formateur qui souhaiterait partager ses connaissances et rejoindre notre plateforme ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCoursesMessageContactUs = () => `Contactez-nous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCoursesMessageOr = () => `Ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_noCoursesMessageBecomeInstructor = () => `Cliquez ici pour les détails`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseVideoHeader_formation = () => `Formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseVideoHeader_entrepreneurs = () => `Entrepreneuriat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseVideoHeader_developers = () => `Développement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseVideoHeader_start_button_label = () => `Commencer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_presentation_title = () => `Présentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_plan_title = () => `Découvrez les modules`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LookPart_title = (params) => `Partie ${params.number} :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const LookPart_preview = () => `Aperçu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_instructor_title = () => `Découvrez votre formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_title = () => `Prérequis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_up_to_date = () => `Contenu à jour :`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_number_interactive_quizs = (params) => `${params.count} quiz interactifs inclus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_number_interactive_quiz = () => `1 quiz interactif inclus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_category = () => `Catégorie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_no_category = () => `Brouillon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_duration = () => `Durée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_level = () => `Niveau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_allLevels = () => `Tous les niveaux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_language = () => `Langue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_subtitles = () => `Sous-titres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_no_subtitles = () => `Non disponibles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_resolution = () => `Résolution`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_fullHD = () => `Résolution en full HD disponible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_4K = () => `Résolution en 4K disponible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BuyCourse_button = () => `Commencer gratuitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_course_title = () => `Formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_title = () => `Filtres`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_all = () => `Toutes les catégories`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_developers = () => `Développement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_entrepreneurs = () => `Entrepreneuriat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_filters_designers = () => `Design`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const courses_where_to_start = () => `Par où commencer ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_levels_all = () => `Tous les niveaux`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_testimonialsCount = (params) => `${params.count} avis`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_number_of_students = (params) => `${params.count} apprenants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_what_you_will_done = () => `Voici ce que vous allez réaliser`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_summary = () => `Plan de la formation`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_projectNumber = (params) => `Projet ${params.number}`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ShowPart_partNumber = (params) => `Partie ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ShowPart_interactiveQuiz = () => `Quiz interactif`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ShowPart_challenge_question = () => `Challenge : 1 question`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ShowPart_challenge_questions = (params) => `Challenge : ${params.number} questions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_certification_title = () => `Décrochez votre certification`


/**
 * @param {{ subject: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_certification_description_subject = (params) => `À la fin de cette formation, vous recevrez une certification attestant de vos compétences avec ${params.subject} si vous réussissez tous vos quizs.`


/**
 * @param {{ subject: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_certification_image_alt_subject = (params) => `Certification de la formation ${params.subject}`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_up_to_date = (params) => `Contenu à jour au ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_language_name = () => `En français 🇫🇷`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_shareable_certification = () => `Certificat d'achèvement partageable`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_mentors_assistance = () => `Accompagnement par nos mentors`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sourceCode_available = () => `Codes sources fournis`


/**
 * @param {{ subject: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_testimonials_subject = (params) => `Avis des apprenants sur notre formation ${params.subject}`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_testimonialsCountWithPlatforms = (params) => `${params.count} avis (Trustpilot et Believemy)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_htmlandcss = () => `HTML et CSS`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_javascript = () => `JavaScript`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_php = () => `PHP`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_prerequisite_react = () => `React`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_testimonial_verifiedByTrustpilot = () => `Développeur web vérifié par Trustpilot`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_instructor = () => `Formateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faqs = () => `FAQs`


/**
 * @param {{ name: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_ourArticlesAboutThis = (params) => `Nos articles sur ${params.name}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_related = () => `Ces formations pourraient vous intéresser`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_breadcrumb_home = () => `Accueil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_breadcrumb_courses = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_summary_show_confidential = () => `Le plan de la formation est encore confidentiel, notre équipe secrète y travaille jour et nuit. Vous pourrez le découvrir dès que la formation sera disponible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const WaitCourse_soon = () => `Bientôt disponible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const WaitCourse_alert = () => `Être alerté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const WaitCourse_sign_tobe_alerted = () => `Inscrivez-vous pour être alerté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsReact = () => `C'est quoi React ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsReact_answer = () => `React est une librairie JavaScript développée par Facebook. Elle permet de créer des interfaces utilisateur interactives et dynamiques. React est basé sur un système de composants qui permet de découper l'interface en petits morceaux réutilisables. React est aujourd'hui très populaire et est utilisé par de nombreuses entreprises.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_timeForAll = () => `Combien de temps me faudra-t-il pour terminer cette formation ?`


/**
 * @param {{ time: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_timeForAll_answer = (params) => `Nos apprenants passent en moyenne ${params.time} pour terminer cette formation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_calendar = () => `Faut-il respecter des horaires pour suivre la formation ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_calendar_answer = () => `Non, vous pouvez suivre la formation à votre rythme, elle se fait entièrement en ligne. Vous pouvez donc suivre cette formation où et quand vous le souhaitez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_level = () => `Quel est le niveau requis pour suivre cette formation ?`


/**
 * @param {{ subject: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_level_answer_1_subject = (params) => `Cette formation est accessible à tous les niveaux. Si vous n'avez jamais utilisé ${params.subject}, il vous suffit de commencer par le début et de suivre les modules dans l'ordre.`


/**
 * @param {{ subject: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_level_answer_2_subject = (params) => `Si vous avez déjà des connaissances avec ${params.subject}, vous pouvez directement vous rendre aux modules qui vous intéressent.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_questions = () => `Est-ce que je peux poser des questions si j'ai un problème ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_questions_answer_1 = () => `Oui, vous pouvez poser des questions à tout moment. Nous avons une équipe de mentors qui est là pour vous aider et répondre à vos questions si vous avez un problème.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_questions_answer_2 = () => `Vous pourrez même rejoindre notre communauté privée pour échanger avec les autres apprenants.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_certification = () => `Est-ce que je recevrais un certificat à la fin de la formation ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_certification_answer = () => `Oui, vous recevrez un certificat de fin de formation à la fin de la formation. Ce certificat est un gage de votre réussite car vous devrez valider tous vos modules et tous les quizs pour le recevoir : c'est une preuve de votre investissement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_uptodate = () => `La formation est-elle à jour avec la dernière version ?`


/**
 * @param {{ subject: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_uptodate_answer_subject = (params) => `Oui, la formation est à jour avec la dernière version de ${params.subject}. Nous mettons régulièrement à jour nos formations pour qu'elles soient toujours à jour avec les dernières technologies.`


/**
 * @param {{ subject: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whyLearnWithAIDominatingTheWorld_subject = (params) => `Pourquoi apprendre ${params.subject} alors que l'intelligence artificielle est en plein essor ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whyLearnWithAIDominatingTheWorld_answer_1 = () => `Parce que l'intelligence artificielle ne remplacera jamais un développeur spécialisé. Elle ne sait déjà pas créer un pauvre site internet en HTML et en CSS, et quand elle y parvient, elle y a été préparée pendant des mois par des équipes de développeurs pour un coût exorbitant en terme d'électricité.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whyLearnWithAIDominatingTheWorld_answer_2 = () => `Par exemple, l'intelligence artificielle de Google, qui a battu le champion du monde de Go (un jeu de stratégie chinois) a été préparée pendant des mois pour cet unique match et a consommé l'équivalent de la consommation électrique d'une ville de 10 000 habitants pendant un an.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whyLearnWithAIDominatingTheWorld_answer_3 = () => `Une intelligence artificielle ne sera jamais capable ni rentable pour créer un site internet complexe sur-mesure avec des fonctionnalités spécifiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsTypescript = () => `C'est quoi TypeScript ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsTypescript_answer_1 = () => `TypeScript est un langage de programmation développé par Microsoft, qui utilise lui-même le JavaScript.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsTypescript_answer_2 = () => `Il offre toutes les fonctionnalités de JavaScript mais possède la particularité d'avoir des fonctionnalités de typage statique pour détecter les erreurs lors de la compilation. Il améliore donc la lisibilité et la maintenabilité de votre code, surtout dans les projets complexes, en permettant la définition explicite des types.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsSymfony = () => `C'est quoi Symfony ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsSymfony_answer_1 = () => `Symfony est un framework PHP open source conçu pour faciliter le développement d'applications web en offrant une structure robuste et des composants réutilisables. Basé sur une architecture MVC (Modèle-Vue-Contrôleur), il propose des fonctionnalités clés telles que la gestion des formulaires, la sécurité et l'accès aux bases de données via l'ORM Doctrine. Sa flexibilité permet de l'adapter aussi bien à des projets simples qu'à des applications plus complexes et scalables.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsSymfony_answer_2 = () => `L'un des principaux avantages de Symfony est sa modularité, car ses composants peuvent être utilisés indépendamment dans d'autres projets.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNextJS = () => `C'est quoi NextJS ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNextJS_answer_1 = () => `NextJS est un framework qui permet de créer des sites web rapidement grâce à React qu'il utilise comme base. Il aide les développeurs à construire des pages qui se chargent très vite et qui sont faciles à optimiser, en combinant les avantages du développement côté client et du développement côté serveur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_faq_whatIsNextJS_answer_2 = () => `En plus de tout ceci, il rend le référencement naturel plus facile, ce qui est un gros avantage pour les sites web qui veulent être bien référencés sur les moteurs de recherche.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_meta_title = () => `Articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_meta_description = () => `Apprenez-en plus grâce à nos articles créés par et pour des personnes comme vous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_title = () => `Articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_headline = () => `Apprenez-en plus grâce à nos articles créés par et pour des personnes comme vous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_write = () => `Écrire un article`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_noArticles = () => `Nous n'avons pas encore d'articles dans cette catégorie, ils arrivent très bientôt.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_noArticlesMessage = () => `Vous avez envie d'être lu par des milliers de personnes ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_noArticlesMessageBecomeWriter = () => `Écrivez un article dès maintenant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const articles_languageNotSupportedYet = () => `Nos articles sont disponibles uniquement en français pour le moment. Nous travaillons à les rendre disponibles dans d'autres langues pour vous offrir la meilleure expérience possible.`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_meta_title = (params) => `Appel avec ${params.fullname}`


/**
 * @param {{ mentorFullname: NonNullable<unknown>, menteeFullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_meta_description = (params) => `Appel entre ${params.mentorFullname} et ${params.menteeFullname}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_message_empty = () => `Le message ne peut pas être vide.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_confirm_completion = () => `Êtes-vous sûr de vouloir marquer l'appel comme étant terminé ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_completed = () => `Appel terminé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_testimonial_empty = () => `Veuillez écrire votre avis.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_testimonial_rating_empty = () => `Veuillez donner une note.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_testimonial_added = () => `Avis ajouté avec succès.`


/**
 * @param {{ refundRate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_confirm_cancellation = (params) => `Êtes-vous sûr de vouloir annuler cet appel ? Vous serez remboursé à hauteur de ${params.refundRate}% du prix de l'appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancelled = () => `Appel annulé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellation_not_allowed = () => `Vous ne pouvez pas annuler cet appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_not_allowed = () => `Vous ne pouvez plus changer l'heure de cet appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_select_slot = () => `Veuillez sélectionner un créneau horaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_slot_already_taken = () => `Le créneau n'est plus disponible. Veuillez rééssayer avec un autre créneau.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_updated = () => `Appel modifié avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_video_empty = () => `Veuillez ajouter une vidéo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_video_error = () => `Une erreur est survenue en ajoutant la vidéo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_video_delete_error = () => `Une erreur est survenue en supprimant l'ancienne vidéo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_no_slot_available = () => `Il n'y a plus de créneaux disponibles sur ce jour pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_prepare_your_call = () => `Préparez votre appel`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_prepare_your_call_with_mentor = (params) => `Préparez votre appel avec ${params.fullname}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_prepare_your_call_with_correspondent = () => `Préparez votre appel avec votre correspondant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_join_call = () => `Rejoindre mon appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_join_call_title = () => `Rejoignez votre appel`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_join_your_call_with_mentor = (params) => `Cliquez sur le bouton ci-dessous pour rejoindre votre appel avec ${params.fullname}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_join_your_call_with_correspondent = () => `Cliquez sur le bouton ci-dessous pour rejoindre votre appel avec votre correspondant.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_endBrief = () => `Votre compte-rendu sur cette session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_video_processing = () => `Votre vidéo est en cours de traitement. Veuillez patienter quelques instants.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_briefDescription = () => `Maintenant que cette session est terminée, nous vous invitons à laisser un compte-rendu sur cette session.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_brief_video = () => `Vidéo de la session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_brief_send_button = () => `Envoyer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_replay_title = () => `Replay pour cette session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_correspondentInfos = () => `Informations sur votre correspondant`


/**
 * @param {{ amount: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_amountEarned = (params) => `Vous avez demandé ${params.amount} € pour le prendre en appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_noCommissionForBelievemy = () => `Believemy ne touchera aucune commission.`


/**
 * @param {{ commissionRate: NonNullable<unknown>, amountWithTaxes: NonNullable<unknown>, amountWithoutTaxes: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_commission = (params) => `La commission prise par Believemy étant de ${params.commissionRate}%, vous percevrez ${params.amountWithTaxes}€ TTC soit ${params.amountWithoutTaxes}€ HT.`


/**
 * @param {{ acceleratorName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_acceleratedIn = (params) => `Accéléré(e) dans "${params.acceleratorName}"`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_onboardingSession = () => `Session d'onboarding (découverte)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellationAvailable = () => `Annulation possible`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_canBeCancelledOneDayBefore = () => `Cet appel peut-être annulé jusqu'à 24h avant le début de l'appel.`


/**
 * @param {{ days: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_canBeCancelledDaysBefore = (params) => `Cet appel peut-être annulé jusqu'à ${params.days} jours avant le début de l'appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_canBeCancelledOneHourBefore = () => `Cet appel peut-être annulé jusqu'à 1h avant le début de l'appel.`


/**
 * @param {{ hours: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_canBeCancelledHoursBefore = (params) => `Cet appel peut-être annulé jusqu'à ${params.hours} heures avant le début de l'appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellationAvailableForFree = () => `Annulation gratuite`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_fullRefund = () => `Remboursement intégral.`


/**
 * @param {{ refundRate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_partialRefund = (params) => `Remboursement de ${params.refundRate}% du total.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellationNotAvailable = () => `Non annulable`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancellationNotAvailableDescription = () => `Votre correspondant ne peut pas annuler cet appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_modifiable = () => `Modifiable`


/**
 * @param {{ maximumChangeCall: NonNullable<unknown>, minimumHoursToChangeCall: NonNullable<unknown>, maximumHoursWithNewCall: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_modifiable_description = (params) => `Modifiable ${params.maximumChangeCall} fois jusqu'à ${params.minimumHoursToChangeCall} heure(s) avant le début de l'appel (${params.maximumHoursWithNewCall} heure(s) de décalage maximum).`


/**
 * @param {{ maximumChangeCall: NonNullable<unknown>, minimumHoursToChangeCall: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_modifiable_description_without_delay = (params) => `Modifiable ${params.maximumChangeCall} fois jusqu'à ${params.minimumHoursToChangeCall} heure(s) avant le début de l'appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_notModifiable = () => `Non modifiable`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_notModifiable_description = () => `Votre correspondant ne peut pas déplacer cet appel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_seeProfile = () => `Voir son profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_discussion = () => `Échanges`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_write_message_placeholder = () => `Écrivez votre message ici...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_send_message = () => `Envoyer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_no_message = () => `Vous n'avez eu aucun échange textuel dans cet appel.`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_testimonial_from = (params) => `Ce qu'en a pensé ${params.fullname}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_no_testimonial = () => `Aucun avis pour l'instant.`


/**
 * @param {{ firstName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_give_testimonial_toMentor = (params) => `Laissez un remerciement à ${params.firstName}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_your_testimonial = () => `Votre avis`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_send_testimonial_button = () => `Publier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot = () => `Modification`


/**
 * @param {{ numberOfChangesAvailable: NonNullable<unknown>, maximumHoursWithNewCall: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_description = (params) => `Vous pouvez déplacer votre rendez-vous encore ${params.numberOfChangesAvailable} fois, et ceci avec ${params.maximumHoursWithNewCall} heures de décalage maximum.`


/**
 * @param {{ numberOfChangesAvailable: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_description_without_delay = (params) => `Vous pouvez déplacer votre rendez-vous encore ${params.numberOfChangesAvailable} fois.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_your_actualSlot = () => `Votre rendez-vous actuel :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_button_continue = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_newDate = () => `Votre nouvelle date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_newHour = () => `Votre nouvelle heure`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_confirmation = () => `Confirmation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_newSlot = () => `Votre nouveau rendez-vous :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_change_slot_button_confirm = () => `Confirmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_finished = () => `Appel terminé`


/**
 * @param {{ minutes: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_started = (params) => `Appel en cours depuis ${params.minutes} minute(s)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancelled_pure = () => `Appel annulé`


/**
 * @param {{ minutes: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_minutesPerCall = (params) => `Appel de ${params.minutes} minutes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_minutes = () => `minutes`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancelledAt = (params) => `Annulé le ${params.date}.`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_originalDate = (params) => `Cet appel devait avoir lieu le ${params.date}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cannotMarkAsCompletedBeforeStart = () => `Vous ne pouvez pas terminer un appel qui n'a pas encore eu lieu.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_markAsCompleted = () => `Marquer comme terminé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_changeSlot = () => `Déplacer mon appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_cancelCall = () => `Annuler mon appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_participants = () => `Participants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_progression = () => `Progression du mentoré`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const call_questionnaires = () => `Questionnaires du mentoré`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_no_token = () => `Token non renseigné`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_title = () => `Justificatif de progression en formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_confidential = () => `Confidentiel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_student = () => `Accéléré :`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_updatedAt = (params) => `Mis à jour le ${params.date}`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_sessionsCompleted = (params) => `${params.count} sessions complétées`


/**
 * @param {{ count: NonNullable<unknown>, total: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_projectsCompleted = (params) => `${params.count} projet(s) complété(s) sur un total de ${params.total}`


/**
 * @param {{ count: NonNullable<unknown>, total: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnairesCompleted = (params) => `${params.count} questionnaire(s) complété(s) sur un total de ${params.total}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnairesList = () => `Liste des questionnaires`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire = (params) => `Questionnaire ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire_validated = () => `Validé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire_notValidated = () => `Non validé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire_started = () => `Commencé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_questionnaire_notStarted = () => `Non commencé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callProgression_sessionsList = () => `Liste des sessions suivies`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const callQuestionnaires_title = () => `Justificatif de progression des quizs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_notQuiz = () => `Ce contenu n'est pas un quiz.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_pending = () => `Cet accéléré n'a pas encore fini ce questionnaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_results = () => `Résultats`


/**
 * @param {{ numberOfValidQuestions: NonNullable<unknown>, total: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_resultsDetails = (params) => `Vous avez répondu correctement à ${params.numberOfValidQuestions} question(s) sur ${params.total}.`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_questionNumber = (params) => `Question ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_goodAnswer = () => `Bonne réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_badAnswer = () => `Mauvaise réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_waitedAnswer = () => `Réponse attendue :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_yourAnswer = () => `Votre réponse :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_explanation = () => `Explication :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_hideDetails = () => `Cacher les détails`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizAnswer_showDetails = () => `Voir les détails`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_numberOfQuestions = (params) => `${params.count} question(s)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_goal = () => `Objectif : Obtenez plus de 70% de réussite pour valider ce quiz`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_itsTimeToTry = () => `Il est temps de tester vos nouvelles connaissances !`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_readCarefully = (params) => `Vous avez ${params.count} question(s) à répondre correctement. N'oubliez pas de bien lire les questions et les réponses avant de valider, sans oublier votre verre d'eau.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quizReadOnly_start = () => `Commencer le quiz`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_meta_title = () => `Tableau de bord de l'accélérateur`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_meta_description = () => `Accédez aux informations à votre accélérateur grâce à votre tableau de bord.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const interfaceLayout_notification_deleted_successfully = () => `Notification supprimée.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const interfaceLayout_verificationEmailSent = () => `Email de confirmation envoyé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_pleaseSelectYourMentor = () => `Veuillez choisir un mentor.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mentorNotAvailable = () => `Mentor non reconnu.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mentoringStartedSuccessfully = () => `Votre mentorat a bien démarré. Notre équipe revient vers vous rapidement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_soon = () => `Prochainement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_seeMore = () => `Voir plus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_recapAboutUpgradingWithMentor = () => `Récapitulatif de la mise à niveau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_incVAT = () => `TTC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_excVAT = () => `HT`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_unlockYourPersonalMentor = () => `Débloquez votre mentor personnel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mensualRecurring = () => `Récurrence mensuelle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_subtotal = () => `Sous-total`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_totalWithVAT = () => `Total (TTC)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_priceByWeek = () => `Soit par semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourCanCancelAnytime = () => `Vous pourrez arrêter votre mentorat n'importe quand.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourBankingInfos = () => `Vos coordonnées bancaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourBankingInfosDescription = () => `Ces informations ne sont pas stockées sur nos serveurs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourWillNotPayToday = () => `Vous ne réglerez rien aujourd'hui, vous pourrez annuler votre essai à chaque instant.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_success = () => `Paiement effectué avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_processing = () => `Paiement en cours.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_requires_payment_method = () => `Paiement refusé. Veuillez rééssayer avec un autre moyen de paiement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_try = () => `Veuillez essayer à nouveau.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_slot_taken = () => `Le créneau n'est plus disponible. Veuillez rééssayer avec un autre créneau.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutFormMentor_invalidCode = () => `Le code que vous utilisez n'est désormais plus valide.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CheckoutForm_pay = () => `Finaliser`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_chooseYourMentor = () => `Quel mentor désirez-vous ?`


/**
 * @param {{ minutes: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_chooseYourMentor_description = (params) => `Bénéficiez d'un mentor attitré par appel vidéo pour vous accompagner dans votre parcours, une fois par semaine pendant ${params.minutes} minutes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_oneCallEachWeek = () => `Un appel en visio / semaine`


/**
 * @param {{ minutes: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_minutesPerCall = (params) => `${params.minutes} minutes / appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_onlyYouTwo = () => `Rien que vous deux, en toute confidentialité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_chooseYourMentor_description_2 = () => `Si vous avez un doute, choisissez un mentor attitré non spécifique.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_iWantAMentorChooseForMe = () => `Je veux un mentor choisi pour moi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_iWantLNL = () => `Je veux Louis-Nicolas comme mentor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_continue = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourBillingInfos = () => `Votre adresse de facturation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourBillingInfosDescription = () => `Ces informations ne sont utilisées que pour générer une facture suite à votre commande.`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_studentSince = (params) => `Accéléré depuis le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourMentor = () => `Votre mentor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_noMentor = () => `Mentor en cours d'attribution`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_bookACall = () => `Réserver un appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mentorship = () => `Mentorat individuel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_mentorship_description = () => `Bénéficiez d'un mentor attitré par appel vidéo.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_startMentorship = () => `Débloquer`


/**
 * @param {{ firstName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_welcome = (params) => `Bonjour, ${params.firstName}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_dashboard = () => `Tableau de bord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_sessionsPublished = () => `Sessions publiées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_sessionPublished = () => `Session publiée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_sessionsEnded = () => `Sessions terminées`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_sessionEnded = () => `Session terminée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_projectsValidated = () => `Projets validés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_projectValidated = () => `Projet validé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_callsCompleted = () => `Coachings terminés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_callCompleted = () => `Coaching terminé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_modules = () => `Modules`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_module = (params) => `Module ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_seeModule = () => `Voir le module`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_noModule = (params) => `Aucun module pour le moment, rendez-vous ici dès le ${params.date}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourMentoring = () => `Mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_yourNextCoaching = () => `Vos coachings à venir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_firstCallDiscover = () => `Séance de découverte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_normalSession = () => `Séance de suivi`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_with = () => `Avec`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_accessCall = () => `Accéder à l'appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_pastCalls = () => `Vos coachings terminés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_cancelledCalls = () => `Vos coachings annulés`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_projects = () => `Projets-passerelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_close = () => `Fermer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_validated = () => `Validé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_invalidated = () => `Non validé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_seeProject = () => `Voir le compte-rendu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_replays = () => `Rediffusions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_seeReplay = () => `Revoir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_documents = () => `Documents`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_documentsDescription = () => `Retrouvez vos documents concernant votre entrée dans l'accélérateur ici.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_entryDocument = () => `Justificatif de rentrée en formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_partners = () => `Partenaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_dashboard_getCoupon = () => `En savoir plus`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_title = () => `Justificatif d'entrée en formation`


/**
 * @param {{ studentFullname: NonNullable<unknown>, programName: NonNullable<unknown>, startDate: NonNullable<unknown>, endDate: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_description1 = (params) => `Je confirme par la présente recevoir ${params.studentFullname} dans la formation professionnalisante “${params.programName}” de l'organisme de formation Believemy (believemy.com) certifié Qualiopi à titre d’étudiant(e) pour une durée de 32 semaines, du ${params.startDate} au ${params.endDate} compris.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_description2 = () => `Cette formation sera dispensée intégralement en ligne et à distance. L'étudiant(e) peut donc être domicilié(e) partout dans le monde.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_description3 = () => `Fait pour faire valoir ce que de droit.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_signature = () => `Monsieur Louis-Nicolas LEUILLET`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_signatureDescription = () => `Dirigeant et fondateur de Believemy.`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const entryDocument_signatureDate = (params) => `Fait à Paris, le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const program_dashboard_meta_title = () => `Tableau de bord du programme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const program_dashboard_meta_description = () => `Accédez aux informations de votre programme grâce à votre tableau de bord.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionCreatedSuccessfully = () => `Discussion créée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pleaseEnterYourMessage = () => `Veuillez entrer un message.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_answerPostedSuccessfully = () => `Message envoyé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_areYouSureYouWantToDeleteThisDiscussion = () => `Voulez-vous vraiment supprimer cette discussion ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionDeletedSuccessfully = () => `Discussion supprimée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionEditedSuccessfully = () => `Discussion modifiée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pleaseSelectAnErrorType = () => `Veuillez sélectionner un type d'erreur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pleaseEnterErrorDescription = () => `Veuillez entrer une description de l'erreur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_errorSentSuccessfully = () => `Merci pour votre aide !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_helpDeleted = () => `Aide spontanée supprimée de cette discussion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_helpAdded = () => `Aide spontanée ajoutée à cette discussion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_pleaseEnterYourTask = () => `Entrez une tâche pour l'ajouter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_youNeedToValidateAllTheQuizQuestions = () => `Vous devez valider tous les quizs à au moins 70% pour obtenir votre certification.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationSentSuccessfully = () => `Félicitations !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizSuccessfullyValidated = () => `+20 points d'xp pour avoir réussi le quiz à 100%`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizLimitedRestart = () => `Vous ne pouvez pas recommencer ce quiz.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizRestartedSuccessfully = () => `Ok ! C'est reparti pour un tour !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_premiumUnlocked = () => `Merci !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_emailVerified = () => `Votre email a bien été vérifié !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourse = () => `Bienvenue sur votre première formation Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseBelievemyOriginals = () => `Bienvenue sur votre première formation Believemy Originals`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_newCourse = () => `Bienvenue sur votre nouvelle formation Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_newCourseBelievemyOriginals = () => `Bienvenue sur votre nouvelle formation Believemy Originals`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseQuoteCitation = () => `"Une formation Believemy Originals, on pleure quand on la commence, mais on pleure encore plus quand on la termine."`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseQuoteAuthor = () => `Dany Boon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseAttention = () => `Attention, avant de commencer votre formation, engagez-vous publiquement à la terminer en rejoignant les autres participants sur notre serveur Discord :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseDiscord = () => `Cliquez ici`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseBestWay = () => `Il n'y a pas de meilleure façon de rester motivé que de partager vos objectifs et vos progrès avec les autres.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseDoIt = () => `Faites-le vraiment avant de cliquer sur le bouton ci-dessous, vous vous remercierez plus tard. 😉`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_firstCourseStart = () => `Commencer la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_newCourseWelcome = () => `Bienvenue sur votre nouvelle formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourse = () => `Cette session est réservée à nos membres premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseDescription1 = () => `Nos formations sont conçues pour vous aider à atteindre vos objectifs le plus vite possible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseDescription2 = () => `Si vous vous arrêtez maintenant, voici ce qui vous attend :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason1 = () => `Vous resterez dans l'incertitude`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason1Description = () => `Sans accès à notre contenu premium, vous risquez de stagner, accumulant des informations incomplètes et désorganisées. Sans une ligne directrice claire, il vous sera difficile de savoir si vous progressez réellement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason2 = () => `Vous passerez à côté de votre potentiel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason2Description = () => `Si vous ne continuez pas cette formation, vous renoncerez à l'opportunité de vous former rapidement et efficacement sur des contenus mis à jour sur les dernières nouveautés. Vous risquerez de vous éparpiller dans des ressources gratuites et non vérifiées, ce qui vous fera perdre du temps et vous découragera.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason3 = () => `Vous perdrez un avantage précieux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseReason3Description = () => `Pendant que vous chercherez des petits contenus gratuits à droite et à gauche, d'autres avancent en ce moment à grands pas en suivant une formation structurée et complète. Vous risquerez de vous retrouver à la traîne , avec des compétences incomplètes et obsolètes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseTrial = () => `En ce moment uniquement :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_lockedCourseTrialDescription = () => `Vous ne risquez rien à essayer notre formation, si vous n'êtes pas satisfait, vous pouvez annuler à tout moment votre période d'essai. D'ailleurs, vous ne serez prélevé que si vous décidez de continuer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_startMyPremium = () => `Continuer dès maintenant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_startMyTrial = () => `Démarrer mon essai pour 0€`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizGoodAnswer = () => `✅ Bonne réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizBadAnswer = () => `❌ Mauvaise réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_answerWasHere = () => `👆 Elle était ici`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizNext = () => `Suivant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizConfirm = () => `Confirmer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizFinish = () => `Voir les résultats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizResults = () => `Résultats`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_quizRestart = () => `Recommencer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unlockCourseTitle = () => `Hop hop hop.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unlockCourseSubtitle = () => `Débloquez cette formation au complet.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unlockCourseDescription = () => `Vous semblez apprécier notre formation (ou alors vous voulez juste jeter un oeil 👀) mais vous n'avez pas encore débloqué la suite. Pour continuer dès maintenant, mettez fin à votre période d'essai facilement et rapidement en cliquant sur le bouton ci-dessous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unlockCourseButton = () => `Continuer la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_progression = () => `Progression`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_content = () => `Contenu`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_sources = () => `Sources`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_questions = () => `Questions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_nav_tasks = () => `Tâches`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_instructor = (params) => `Votre formateur pour cette formation est ${params.fullname}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questions = () => `Questions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionTitle = () => `Avant de poser une question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionDescription = () => `Quelques petites choses peuvent vous faire gagner du temps :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionAdvice1 = () => `Commencez par chercher une solution vous-même, par exemple avec l'aide d'une intelligence artificielle comme Gemini ou encore ChatGPT;`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionAdvice2 = () => `Vérifiez que votre question n'a pas déjà été posée par un autre apprenant dans les discussions de la formation;`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionAdvice3 = () => `Donnez autant de détails que nécessaire et soignez votre message.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_timecode = () => `Code de la vidéo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionTitle = () => `Titre de la question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionTitlePlaceholder = () => `Titre de votre question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionContent = () => `Contenu de la question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionContentPlaceholder = () => `Écrivez votre question ici...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_cancel = () => `Retour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_send = () => `Publier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_backToQuestions = () => `Retour aux questions`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussion = (params) => `Date : ${params.date}`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_numberOfParticipants = (params) => `${params.count} participant(s)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionEdit = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_discussionDelete = () => `Supprimer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_markAsGuide = () => `Marquer comme aide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_unmarkAsGuide = () => `Démarquer comme aide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_questionAnswerPlaceholder = () => `Tapez votre message ici...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_save = () => `Mettre à jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_helpBadge = () => `Aide spontanée`


/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_numberOfMessages = (params) => `${params.count} message(s)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_markAsGuide = () => `Message marqué comme meilleure réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_areYouSureYouWantToDeleteThisMessage = () => `Voulez-vous vraiment supprimer ce message ? Cette action est irréversible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_messageDeleted = () => `Message supprimé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_messageUpdated = () => `Message modifié avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_edit = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_delete = () => `Supprimer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_markAsBestAnswerButton = () => `Meilleure réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_bestAnswerBadge = () => `Meilleure réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sendAnswer = () => `Ajouter une réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_currentSession = () => `Session actuelle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_allSessions = () => `Toutes les sessions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_noQuestions = () => `Il n'y a aucune question sur cette session pour le moment. Vous pouvez en ajouter une ici.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_noAccess = () => `Vous devez être abonné à Believemy Premium pour pouvoir ajouter une question.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_askQuestionButton = () => `Ajouter une question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sources = () => `Sources`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sourcesDescription = () => `Des sources sont liées pour cette session. Téléchargez ces dernières en cliquant sur le bouton en-dessous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_downloadSources = () => `Télécharger les sources`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_tasks = () => `Tâches`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_tasksDescription = () => `Notez vos prochains objectifs ou les prochaines tâches à réaliser pour ne rien rater.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_taskPlaceholder = () => `Entrez une tâche pour l'ajouter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_noTasks = () => `Vous n'avez aucune tâche.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationProgression = () => `Progression`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certification = () => `Certification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationLinkedin = () => `Félicitations ! Ajoutez votre certification sur Linkedin :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationLinkedinButton = () => `Ajouter en un clic`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationMalt = () => `Pensez aussi à l'ajouter sur les plateformes de recrutement ou de freelancing comme Malt pour augmenter la valeur de votre compte et l'attrait auprès des entreprises.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationPending = () => `Terminez votre formation à 100% pour obtenir votre certification de fin de formation Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationProgressionPercent = () => `Vous êtes actuellement à :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationComplete = () => `Votre formation est terminée avec succès à 100% ? Récupérez votre certification !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_seeCertification = () => `Voir ma certification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_getCertification = () => `Récupérer ma certification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_expertise = () => `Expertise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_reportError = () => `Signaler un problème`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_reportErrorDescription = () => `Vous rencontrez un problème avec cette session ? Signalez-le ici.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_errorType = () => `Type de problème`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_selectErrorType = () => `Sélectionnez un problème`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_inappropriateContent = () => `Contenu inapproprié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_deppreciatedContent = () => `Contenu obsolète`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_other = () => `Autre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_errorDescription = () => `Description du problème`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_sendError = () => `Signaler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_reinitializeProgression = () => `Réinitialiser la session`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_checkProgression = () => `Valider la session`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const PartInterface_part = (params) => `Partie ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Badge_new = () => `Nouveau`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Badge_updated = () => `Mis à jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const PartInterface_questions = () => `Questions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const PartInterface_question = () => `Question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const PartInterface_quiz = () => `On s'entraîne sur nos connaissances !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const accelerator_content_certificationWillBeenSent = () => `Votre certification vous sera délivrée après convocation de l'équipe pédagogique.`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_meta_title = (params) => `Certification #${params.number}`


/**
 * @param {{ fullname: NonNullable<unknown>, courseName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_meta_description = (params) => `Certification officielle délivrée par Believemy pour ${params.fullname} sur : ${params.courseName}`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_title = (params) => `Certification #${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_seeCourse = () => `Voir la formation associée :`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_description = (params) => `Cette page certifie de l'authenticité de la certification #${params.number}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_share = () => `Partagez cette page plutôt que votre certification`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_shareDescription = () => `Une certification téléchargée peut-être falsifiée, ce qui n'est pas le cas d'un lien que vous partagez sur votre site internet ou sur vos profils professionnels vers Believemy (Malt, LinkedIn).`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const certification_shareLinkedin = () => `Ajouter sur LinkedIn en un seul clic`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationFromBelievemy = () => `L'équipe pédagogique de Believemy vous délivrera votre certification sous peu. N'hésitez pas à nous contacter si vous souhaitez nous relancer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const course_content_certificationDescription = () => `Vous devez également réussir tous les quizs et vos éventuels projets pour décrocher votre certification.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_meta_title = () => `Cette page n'existe pas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_meta_description = () => `Cette page n'existe pas ou a été supprimée.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_button = () => `Retourner à l'accueil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_meta_title = () => `Messagerie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_meta_description = () => `Discutez avec vos correspondants et vos mentors en toute simplicité.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_empty_answer = () => `Veuillez entrer une réponse.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_delete_answer_confirmation = () => `Voulez-vous vraiment supprimer ce message ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_answer_deleted = () => `Message supprimé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_delete_discussion_confirmation = () => `Voulez-vous vraiment supprimer cette discussion ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_discussion_deleted = () => `Discussion supprimée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_title = () => `Messagerie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_no_message = () => `Vous n'avez pas encore de message`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_load_more = () => `Charger plus de messages`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_participants = () => `Participants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_participant = () => `Participant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_private_discussion = () => `Discussion privée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_reply = () => `Répondre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const messenger_no_discussion_selected = () => `Toutes vos discussions, en un seul endroit.`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_meta_title = (params) => `${params.fullname}`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_meta_description = (params) => `Découvrez le profil de ${params.fullname} sur Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message_notVerifiedEmail = () => `Vous devez vérifier votre adresse email pour pouvoir envoyer des messages.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message_success = () => `Message envoyé avec succès.`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_cover_alt = (params) => `Photo de couverture de ${params.fullname}`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_photo_alt = (params) => `Photo de profil de ${params.fullname}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message_button = () => `Envoyer un message`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_edit_profile_button = () => `Modifier mon profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_nav_about = () => `À Propos`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_nav_articles = () => `Articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_nav_courses = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_portrait = () => `Portrait`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_projects = () => `Projets`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_projects_empty = () => `Aucun projet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_projects_no_description1 = () => `Ce projet ne contient aucune description.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_projects_no_description2 = () => `Believemy utilise les balises open graph pour afficher les informations des projets.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_certifications = () => `Certifications`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_certification_delivered = (params) => `Délivrée le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_certifications_empty = () => `Cet utilisateur n'a pas de certification.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_articles = () => `Articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_articles_empty = () => `Cet utilisateur n'a pas encore écrit d'article.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_courses = () => `Formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_courses_empty = () => `Cet utilisateur n'a pas encore créé de formation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_infos = () => `Informations`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_inscriptionDate = (params) => `Inscrit depuis le ${params.date}`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_lastActivity = (params) => `Dernière activité le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_fragments = () => `Fragments`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message = (params) => `Envoyer un message à ${params.fullname}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profile_send_message_placeholder = () => `Écrivez votre message ici...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const believemy_originals = () => `Une production Believemy Originals.`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DisplayArticle_write = (params) => `Écrit par ${params.fullname}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_meta_title = () => `Mon compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_meta_description = () => `Gérez vos informations personnelles et vos paramètres de compte.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_pseudo_error = () => `Le pseudo ne peut pas commencer par 'believer-'`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_data_updated = () => `Informations modifiées avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_password_updated = () => `Mot de passe modifié avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_message_error = () => `Veuillez entrer un message d'au moins 10 caractères.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscription_canceled = () => `Abonnement résilié avec succès. Merci pour votre confiance !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_code_error = () => `Le code est obligatoire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_code = () => `Je confirme vouloir perdre mes avantages maintenant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_photo_error = () => `Veuillez ajouter un logo valide (png / jpg / jpeg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_project_added = () => `Projet ajouté avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_project_removed = () => `Projet supprimé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_error = () => `Une erreur est survenue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_photo_updated = () => `Pas mal du tout.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cover_updated = () => `Jolie couverture.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_redirect = () => `Nous vous remercions pour votre confiance.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription = () => `Résilier mon abonnement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_mentoring = () => `Mentorat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_premium = () => `Abonnement Believemy Premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_description = () => `Vos avantages prendront fin immédiatement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_description_bis = () => `Il est temps de nous dire au revoir ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_mentoring_description = () => `Résilier son mentorat, c'est ne plus avoir un mentor pour vous accompagner dans votre projet et ne plus bénéficier des avantages que vous avez pu avoir jusqu'à présent.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_premium_description = () => `Résilier son abonnement à Believemy Premium, c'est dire au revoir à tous les avantages que vous avez pu avoir jusqu'à présent et quitter la communité qui soutient les créateurs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_message = () => `Cependant, nous sommes conscients que vous pouvez avoir des raisons de nous quitter. Si vous souhaitez tout de même résilier votre abonnement, précisez comment nous aurions pu faire en sorte de vous garder avec nous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_trial = () => `Chaque carte bancaire est limitée à une seule période d'essai.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_message_placeholder = () => `Pourquoi souhaitez-vous résilier votre abonnement ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_cancel_subscription_button = () => `Résilier mon abonnement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_title = () => `Mon compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_description = () => `D'ici, vous pouvez modifier votre expérience sur Believemy afin qu'elle soit parfaitement adaptée à vos besoins et aux valeurs que vous portez. Parce que ce n'est pas à vous de vous adapter, mais à nous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_nav_informations = () => `Informations personnelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_nav_invoices = () => `Factures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_nav_subscriptions = () => `Abonnements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations = () => `Informations personnelles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_description = () => `Vous pouvez modifier vos informations personnelles sur cette page. Votre mot de passe vous sera demandé pour toute modification, juste par sécurité.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_email = () => `Adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_email_placeholder = () => `Votre adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_email_required = () => `L'email est obligatoire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_email_error = () => `L'email n'est pas valide.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_firstName = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_firstName_placeholder = () => `Votre prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_firstName_required = () => `Le prénom est obligatoire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_lastName = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_lastName_placeholder = () => `Votre nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_lastName_required = () => `Le nom est obligatoire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_timezone = () => `Fuseau horaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_pseudo = () => `Pseudo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_pseudo_placeholder = () => `Votre pseudo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_pseudo_error = () => `Votre pseudo doit faire entre 3 et 30 caractères, sans espace ni caractères spéciaux (sauf le point et le tiret).`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_presentation = () => `Présentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_presentation_placeholder = () => `Présentez-vous en quelques mots...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_presentation_error = () => `La présentation ne peut pas dépasser 90 caractères.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_website = () => `Site internet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_website_placeholder = () => `Exemple : https://...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_twitter = () => `X (Twitter)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_twitter_placeholder = () => `Exemple : https://twitter.com/...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_github = () => `GitHub`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_github_placeholder = () => `Exemple : https://github.com/...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_linkedin = () => `LinkedIn`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_linkedin_placeholder = () => `Exemple : https://linkedin.com/in/...`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_birthday = () => `Date de naissance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_january = () => `Janvier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_february = () => `Février`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_march = () => `Mars`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_april = () => `Avril`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_may = () => `Mai`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_june = () => `Juin`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_july = () => `Juillet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_august = () => `Août`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_september = () => `Septembre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_october = () => `Octobre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_november = () => `Novembre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_december = () => `Décembre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_discord = () => `Pseudo Discord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_discord_placeholder = () => `Votre pseudo Discord (sans les #)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_discord_description = () => `Discord est une application de chat vocal et textuel spécialement conçue pour discuter. Vous pouvez ajouter votre pseudo ici si vous le souhaitez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_password_security = () => `Mot de passe actuel (par sécurité)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_password_required = () => `Veuillez préciser votre mot de passe actuel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_informations_save = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_projects = () => `Projets`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_projects_description = () => `Vous pouvez gérer les projets sur votre profil ici. Believemy lit les balises opengraph de chacun de vos projets pour afficher une image, une description et un titre.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_projects_add = () => `Ajouter un projet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_projects_add_placeholder = () => `Adresse du projet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_billing = () => `Facturation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_billing_description = () => `Vos informations de facturation nous permettent de vous proposer une expérience plus agréable lors de vos processus d'accès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const address_country_required = () => `Le pays est obligatoire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_billing_save = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security = () => `Sécurité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_description = () => `Vous pouvez changer votre mot de passe dans cette partie.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_oldPassword = () => `Mot de passe actuel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_oldPassword_placeholder = () => `Votre mot de passe actuel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_oldPassword_required = () => `Le mot de passe actuel est obligatoire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_newPassword = () => `Nouveau mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_newPassword_placeholder = () => `Votre nouveau mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_newPassword_required = () => `Le nouveau mot de passe est obligatoire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_security_save = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_profilePicture = () => `Photo de profil`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_profilePicture_chooseFile = () => `Choisir un fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_profilePicture_save = () => `Modifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_coverPicture = () => `Photo de couverture`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_coverPicture_chooseFile = () => `Choisir un fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_coverPicture_save = () => `Modifier`


/**
 * @param {{ orderNumber: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_invoice_orderNumber = (params) => `Commande n°${params.orderNumber}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_invoice_refunded = () => `Commande remboursée`


/**
 * @param {{ total: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_invoice_total = (params) => `Montant : ${params.total}€ TTC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_noInvoices = () => `Vous n'avez aucune facture.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions = () => `Abonnements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current = () => `Plans actuels`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_description = () => `Voici les abonnements Believemy Premium que nous proposons actuellement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_mensual = () => `Abonnement mensuel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_euro = () => `€ / mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_sixMonths = () => `Abonnement 6 mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_current_annually = () => `Abonnement 12 mois`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_trial = (params) => `Votre essai a commencé le ${params.date}.`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_trialEnd = (params) => `Il se terminera le ${params.date}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_cancelTrial = () => `Annuler mon essai`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_started = (params) => `Votre abonnement a commencé le ${params.date}.`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_nextBilling = (params) => `Votre prochain prélèvement aura lieu le ${params.date}.`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_engagement = (params) => `Vous pourrez résilier à la fin de votre période d'engagement, soit le ${params.date}.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_month = () => `Mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_year = () => `An`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_cancel = () => `Résilier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions = () => `Passez aux choses sérieuses`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_description = () => `Vous n'avez pas encore d'abonnement Believemy Premium. Pourquoi ne pas essayer gratuitement ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_why1 = () => `Accès en illimité à tous notre catalogue de formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_why2 = () => `Assistance ultra-prioritaire en moins de 24 heures`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_why3 = () => `Certifications officielles Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_why4 = () => `Et bien plus encore.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const account_subscriptions_noSubscriptions_button = () => `En savoir plus`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_title = (params) => `Facture #${params.number}`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_created = (params) => `Créée le ${params.date}`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_paid = (params) => `Payée le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_company = () => `Créée par :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_customer = () => `Facturée à :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_paymentMethod = () => `Mode de paiement :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_creditCard = () => `Carte bancaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_designation = () => `Désignation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_amountExclVAT = () => `Montant HT`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_totalExclVAT = () => `Total HT`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_expedition = () => `Frais d'expédition`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_amountVAT = () => `Montant TVA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_totalInclVAT = () => `Total TTC`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_amountRefunded = () => `Montant remboursé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_VATNotApplicable = () => `TVA non applicable, article 293-B du CGI`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_VATExempted = () => `Exonération de TVA en vertu de l’article 262 ter I du code général des impôts`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const invoice_legalNotice = () => `En cas de retard de paiement, seront exigibles conformément à l'article L 441-6 du code du commerce, une indemnité calculée sur la base de trois fois le taux de l'intérêt légal en vigueur ainsi qu'une indemnité forfaitaire pour frais de recouvrement de 40 €.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_notConnected_meta_title = () => `Le nouveau Golden Standard de la formation pour les développeurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_notConnected_meta_description = () => `Believemy est une plateforme de formation en ligne pour les développeurs et les freelances. Plus de 50 000 personnes formées.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_meta_title = () => `Tableau de bord`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_meta_description = () => `Believemy est une plateforme de formation en ligne pour les développeurs et les freelances. Plus de 50 000 personnes formées.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_see_button = () => `Regarder`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_new_title = () => `Nouveautés`


/**
 * @param {{ time: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ProgressionCard_minutesPassed = (params) => `${params.time} mins sur la formation`


/**
 * @param {{ time: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ProgressionCard_minutePassed = (params) => `${params.time} min sur la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Highlight_episode = () => `Épisode`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Highlight_course = () => `Formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Highlight_announcement = () => `Annonce`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Episode_episode = () => `Épisode`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_live = () => `Événement en direct`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_call = () => `Appel de mentorat`


/**
 * @param {{ fullname: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_call_title = (params) => `Vous avez un appel avec ${params.fullname}.`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_today = (params) => `[Aujourd'hui à] ${params.date}`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_tomorrow = (params) => `[Demain à] ${params.date}`


/**
 * @param {{ day: NonNullable<unknown>, hour: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_nextWeek = (params) => `${params.day} [prochain à] ${params.hour}`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_yesterday = (params) => `[Hier à] ${params.date}`


/**
 * @param {{ minutesPerCall: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_minutesPerCall = (params) => `${params.minutesPerCall} minutes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const BookedEvent_join = () => `Accéder à cet appel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_seasons = () => `Saisons`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_season = () => `Saison`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_serie = () => `Série`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_watch = () => `Regarder`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_presentation = () => `Présentation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_episodes = () => `Épisodes`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SerieModale_seasonNumber = (params) => `Saison ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_yourCalls_title = () => `Vos rendez-vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_progressions_title = () => `Reprenez là où vous en étiez`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_courses_title = () => `Nos dernières formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_articles_title = () => `Nos derniers articles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_believemyOriginals_title = () => `Séries Believemy Originals`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_connected_mentoring_title = () => `Faites-vous accompagner individuellement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_noEpisodes = () => `La série arrive prochainement sur Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_youAreWatching = () => `Vous regardez`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_season = (params) => `Saison ${params.number}`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_episode = (params) => `Épisode ${params.number}`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_shortEpisode = (params) => `Ep. ${params.number}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_watch_noSubtitles = () => `Aucun`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const serie_language = () => `Cette série n'est pas disponible dans votre langue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_createAccountButton = () => `Créer un compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const SideHeader_loginButton = () => `Se connecter`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const UserLayout_login = () => `Connexion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const UserLayout_signup = () => `Inscription`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseCard_soon_title = () => `En cours de production`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CourseCard_soon_summary = () => `Notre première formation est en cours de préparation. Elle sera bientôt disponible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ArticleCard_soon_title = () => `En cours de rédaction`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ArticleCard_soon_summary = () => `Notre premier article est en cours de rédaction. Il sera bientôt disponible.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_noLanguageFoundWelcome1 = () => `Believemy est une plateforme de formation en ligne pour devenir développeur web ou exercer un métier du numérique comme le marketing digital, le design ou la data science.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_noLanguageFoundWelcome2 = () => `Nous venons de nous ouvrir à l'international, nos nouvelles productions arrivent bientôt. Si vous voulez apporter votre pierre à l'édifice, n'hésitez pas à nous contacter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_noLanguageFoundTitle = () => `Le nouveau Golden Standard de la formation pour les développeurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_h1 = () => `Le nouveau Golden Standard de la formation pour les développeurs`


/**
 * @param {{ number: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_title = (params) => `+${params.number} devs formés / an`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_jeromeDelauney = () => `Il a créé CO-LAB Digital`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_shompothKhan = () => `Il travaille maintenant chez Jow comme Développeur React Native`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_mansourMahamat = () => `Il crée maintenant des projets à succès sur Product Hunt`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_manonEche = () => `Elle est maintenant freelance épanouie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const TestimonialTooltip_allianceTshindayi = () => `Il est maintenant chez Freshpay`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HeroForMain_title = () => `Le nouveau Golden Standard de la formation pour les développeurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HeroForMain_headline = () => `Performez sur nos formations enseignées par les meilleurs formateurs au monde. Rejoignez nos accélérateurs ou faites-vous mentorer individuellement pour votre réussite.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HeroForMain_button = () => `S'inscrire gratuitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_meta_title = () => `Connectez-vous à Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_meta_description = () => `Connectez-vous à votre compte Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_meta_title = () => `Inscrivez-vous à Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_meta_description = () => `Inscrivez-vous sur Believemy pour accéder à nos formations.`


/**
 * @param {{ fullName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const welcome_back = (params) => `Bon retour parmi nous ${params.fullName}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_connected = () => `Bon retour parmi nous !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_unknowedError = () => `Erreur de connexion inconnue.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_title = () => `Connexion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_headline = () => `Connectez-vous pour accéder à votre compte Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_google = () => `Connexion avec Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_else = () => `Sinon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_or = () => `Ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_email = () => `Adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_email_required = () => `Veuillez renseigner votre adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_email_invalid = () => `Veuillez renseigner une adresse email valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_password = () => `Mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_password_required = () => `Veuillez renseigner votre mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_submit = () => `Connexion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_forgottenPassword = () => `Mot de passe oublié ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_noAccount = () => `Pas encore de compte ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_createYourAccount = () => `Créez un compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_meta_title = () => `Vérifiez votre adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_meta_description = () => `Vérifiez votre adresse email pour accéder à votre compte Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_title = () => `Un email vous a été envoyé`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_description_1 = () => `Vous devez confirmer votre adresse email grâce à celui que nous vous avons envoyé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_description_2 = () => `Si vous n'avez pas reçu l'email, vérifiez vos spams ou cliquez sur le bouton ci-dessous pour en recevoir un nouveau.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_resend_email = () => `Renvoyer l'email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_email_sent = () => `Un nouvel email vient de vous être envoyé.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_connected = () => ` Vous avez reçu un email de notre part sur l'adresse suivante :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_disconnection = () => `Si vous souhaitez vous déconnecter :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const verify_account_disconnection_link = () => `Cliquez ici`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_meta_title = () => `Onboarding`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_meta_description = () => `Complétez votre profil pour accéder à toutes les fonctionnalités de Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_invalidPhoto = () => `Veuillez ajouter un logo valide (png / jpg / jpeg)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_error = () => `Une erreur est survenue.`


/**
 * @param {{ firstName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_welcome = (params) => `Bienvenue ${params.firstName}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_welcomeHeadline = () => `Afin de vous proposer une expérience 100% personnalisée, nous avons juste besoin de quelques précisions sur vous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_start = () => `Commencer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_category = () => `Qu'est-ce qui vous définit le mieux ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_entrepreneurs = () => `Je suis entrepreneur(e)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_developers = () => `Je suis développeur(euse)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_continue = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_goal = () => `Quel est votre objectif ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_discoveringEntrepreneur = () => `Découvrir l'entrepreneuriat`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_creatingBusiness = () => `Créer une entreprise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_becomingFreelancer = () => `Devenir freelance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_learningInvest = () => `Apprendre à investir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_growingBusiness = () => `Faire croître mon entreprise actuelle`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_level = () => `Est-ce que vous savez déjà programmer ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_beginner = () => `Pas du tout`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_novice = () => `J'ai quelques petites bases`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_intermediate = () => `Je m'y connais déjà bien`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_advanced = () => `Je suis un professionnel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_goalForDev = () => `Et quel est votre objectif ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_createFirstWebsite = () => `Créer mon premier site internet`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_createFirstApp = () => `Créer ma première application mobile`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_createMySaas = () => `Créer mon SAAS`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_createMyStartup = () => `Créer ma startup`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_dontKnow = () => `Je ne sais pas encore`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_birthday = () => `Au fait, quelle est votre date de naissance ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_birthdayHeadline = () => `Votre date de naissance va nous permettre de mieux vous recommander ce qui pourrait vous plaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_photo = () => `Démarquez-vous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_photoHeadline = () => `Que dites-vous de mettre une photo de profil ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_upload = () => `Choisir un fichier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_skip = () => `Ignorer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_sources = () => `Comment nous avez-vous connu ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_sourcesHeadline = () => `Ceci nous permet de mieux savoir d'où vous venez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromYoutube = () => `Depuis YouTube`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromTiktok = () => `Depuis TikTok`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromInstagram = () => `Depuis Instagram`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromFacebook = () => `Depuis Facebook`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromLinkedin = () => `Depuis LinkedIn`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromTwitter = () => `Depuis X (Twitter)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromThreads = () => `Depuis Threads`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromSearchEngines = () => `Depuis un moteur de recherche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromAds = () => `Depuis une publicité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromRecommendations = () => `Quelqu'un m'a parlé de vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_fromOther = () => `Autre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_confirm = () => `Terminer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_ready = () => `Tout le monde vous attend.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_readyLabelForButton = () => `C'est parti`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const onboarding_titleForReady = () => `Tout est prêt !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_meta_title = () => `Mot de passe oublié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_meta_description = () => `Récupérez votre mot de passe Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenpassword_notSamePasswords = () => `Les mots de passe ne sont pas identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_passwordChanged = () => `Changement effectué !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_passwordChangedDescription = () => `Votre mot de passe a été modifié avec succès : vous pouvez maintenant vous connecter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_login = () => `Connexion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_title = () => `Vous revoilà !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_description = () => `Quel est le nouveau mot de passe que vous souhaitez ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_newPassword = () => `Nouveau mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_required = () => `Cette valeur est requise`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_confirmPassword = () => `Nouveau mot de passe (confirmation)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_reset = () => `Réinitialiser`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_new = () => `Mot de passe oublié`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_newDescription = () => `Vous avez oublié votre mot de passe ? Veuillez renseigner votre adresse email, nous vous enverrons un email avec un lien pour le mettre à jour.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_email = () => `Adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_emailRequired = () => `Veuillez renseigner votre adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_emailInvalid = () => `Veuillez renseigner une adresse email valide`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_submit = () => `Réinitialiser`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_redirectForLogin = () => `Finalement, non !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_emailSent = () => `Hop ! C'est dans la boîte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const forgottenPassword_emailSentDescription = () => `Si cette adresse est liée à un compte Believemy, vous allez recevoir un email de réinitialisation dans quelques secondes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_meta_title = () => `Believemy Premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_meta_description = () => `Découvrez Believemy Premium, l'abonnement qui vous permet d'accéder à toutes nos formations et à des avantages exclusifs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_error_name = () => `Veuillez renseigner votre prénom et nom.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_description = () => `Accédez à toutes les formations de notre catalogue en illimité et en vous faisant accompagner par nos experts.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enterprises = () => `Certains de leurs développeurs sont passés par chez nous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_dontLetYourSkills = () => `Ne laissez pas vos compétences devenir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_dontLetYourSkills2 = () => `obsolètes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_dontLetYourSkillsHeadline1 = () => `Le monde évolue, les outils et les technologies aussi. Formez-vous en continu pour rester compétitif.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_dontLetYourSkillsHeadline2 = () => `Continuer de se former, c'est s'assurer de rester au sommet !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_withoutBelievemy = () => `Sans Believemy Premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_withoutBelievemyDescription = () => `Sans Believemy Premium, vous vous formez avec des petits bouts de tutoriels à gauche à droite, sans aucun suivi et sur des contenus qui ne sont pas toujours à jour. Vous passez du temps à chercher les bonnes informations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_withBelievemy = () => `Avec Believemy Premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_withBelievemyDescription = () => `Avec Believemy Premium, vous avez accès à l'ensemble de nos formations en illimité et vous pouvez profiter de notre accompagnement sur des contenus toujours à jour avec les dernières versions ainsi qu'aux bonnes pratiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisWillHappen1 = () => `Si vous ne rejoignez pas`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisWillHappen2 = () => `Believemy Premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillBeSolo = () => `Vous resterez seul`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillBeSoloDescription = () => `Vous resterez seul face à vos problèmes et vos questions. Vous n'aurez pas de mentor pour vous aider à progresser et à vous améliorer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillBeOutOfDate = () => `Vous serez obsolète`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillBeOutOfDateDescription = () => `Vous serez obsolète et vous n'aurez pas les compétences nécessaires pour répondre aux besoins du marché. Vous serez dépassé par les nouvelles technologies.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillLooseTime = () => `Vous perdrez du temps`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youWillLooseTimeDescription = () => `Vous perdrez du temps à chercher les bonnes informations et à les mettre en pratique. Vous n'aurez pas de suivi pour vous aider à progresser.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisMakesDifference = () => `Ça fait la différence !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisMakesDifference_for_freelance = () => `Le TJM (tarif journalier moyen) d'un freelance sur les technologies enseignées sur Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_thisMakesDifference_for_employee = () => `Le salaire moyen d'un développeur sur les technologies enseignées sur Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_sources = () => `D'après l'enquête de Malt disponible ici :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_see = () => `Voir`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_employeeSources = () => `D'après l'enquête sur Indeed disponible ici :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_ourPricing = () => `Faites le bon choix.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_ourPricingDescription = () => `À quelle durée souhaitez-vous vous engager ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_monthlySubscription = () => `Abonnement sans engagement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_monthlySubscriptionDescription = () => `Pour ceux qui veulent profiter de l’ensemble des formations de Believemy, sans engagement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_monthlySubscriptionPriceWithoutAnyEngagement = () => `Par mois / sans engagement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_startThisSubscription = () => `Commencer cet abonnement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_startForFree = () => `Essayer gratuitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage1 = () => `Accès à toutes nos formations au catalogue en illimité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage2 = () => `Intégration à notre communauté privée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage3 = () => `Assistance technique sur les formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage4 = () => `Certifications officielles Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage5 = () => `Accès à des événements exclusifs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantage6 = () => `Suivre sa progression`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_6MonthsSubscription = () => `Abonnement 6 mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_6MonthsSubscriptionDescription = () => `Pour ceux qui veulent profiter de l’ensemble de Believemy, sans aucune limitation et avec une réduction pour leur fidélité.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_allTheAdvantages = () => `Tout ce qu’il y a dans l'abonnement sans engagement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantageFor6Months = () => `Avec une remise de 50% sur le prix mensuel pour vous former sur le moyen terme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_annuallySubscription = () => `Abonnement 12 mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_annuallySubscriptionDescription = () => `Pour ceux qui veulent profiter de l’ensemble de Believemy pour continuer à se former sur le long terme.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_advantageForAnnually = () => `Avec une remise de 65% sur le prix mensuel pour vous aider à vous former sur le long terme`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_theMostPopular = () => `Le plus populaire`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_whySellSubscriptions = () => `Pour un abonnement plutôt que des achats à l'unité ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_questions = () => `Vous avez une question ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_start = () => `À quelle date commencera mon abonnement ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_start_description = () => `Votre abonnement à Believemy commencera à la date de votre adhésion à l'un de nos tarifs. Vos privilèges seront automatiquement ajoutés à votre compte.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_debit = () => `Vais-je être débité pendant mon essai gratuit ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_debit_description = () => `Non, vous ne serez débité que si vous n'annulez pas votre essai avant la fin de la période.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_help = () => `Suis-je aidé si j'ai besoin d'aide sur une formation ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_help_description = () => `Toujours ! Nos formateurs se feront un plaisir de vous aider dès que vous aurez besoin d'aide, il suffira de poser votre question sur la formation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_cancel = () => `Comment vais-je pouvoir annuler mon abonnement ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_cancel_description = () => `Vous pourrez annuler votre abonnement selon votre convenance directement sur votre compte Believemy selon votre durée minimale d'engagement. Aucune justification ne vous sera réclamée et la fin de votre abonnement s'effectuera dans l'immédiat.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_paid = () => `Puis-je effectuer un virement pour régler mon abonnement ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_paid_description = () => `Oui, vous pouvez effectuer un virement pour régler votre abonnement. Seuls les abonnements annuels sont disponibles pour le moment, avec un paiement annuel. Veuillez nous contacter pour plus d'informations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_diff = () => `Quelle est la différence entre la formule mensuelle et annuelle ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_faq_diff_description = () => `Nos offres annuelles vous permettent de bénéficier d'une réduction pour votre fidélité. Les avantages sont identiques.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_haveAnotherQuestion = () => `J'ai une question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThing = () => `One More Thing`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingDescription = () => `Payer un abonnement mensuel, c'est bien, mais pourquoi ne proposer qu'un abonnement et pas des paiements en une fois ? C'est une question que vous vous posez peut-être. Nous avons fait le choix de ne proposer qu'un abonnement pour plusieurs raisons :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason1 = () => `L'honnêteté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason1Description = () => `Nous avons fait le choix de l'honnêteté avec vous. Nous ne voulons pas vous faire payer plein tarif pour la même formation tous les ans pour la dernière version d'une formation que vous avez déjà payé. Les technologies évoluent, les formations aussi. nous préférons vous proposer un abonnement mensuel pour vous permettre de vous former sur les dernières technologies tout au long de l'année, sans frais supplémentaires.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason2 = () => `La liberté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason2Description = () => `Avec un abonnement mensuel, vous avez la possibilité de vous former sur toutes nos formations sans aucune limite. Avec un achat unique, vous seriez limité à une seule formation (sauf si vous dépensez une fortune).`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_oneMoreThingReason2DescriptionBis = () => `Ce serait quand même dommage de vous limiter.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const testimonials_title = () => `C'est vous qui en parlez le mieux`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const testimonial_1 = () => `Clair et précis, l'on sait d'où l'on vient et d'où l'on se dirige. 100% efficace pour débuter ou reprendre ses bases, et pousser davantage dans la bonne compréhension du Javascript moderne, au top ! Les cours sont au jour et le formateur disponible pour éclaircir le moindre souci rencontré, excellent !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const testimonial_2 = () => `Les cours sont vraiment bien expliqués, le formateur transmet ses connaissances avec passion et pédagogie, je recommande sans hésiter, il y a pleins de bonnes surprises à venir, le prix de l'abonnement à l'année est très raisonnable, vraiment très satisfait.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const testimonial_3 = () => `La meilleure plateforme de formation ! Tout est limpide, simple, nous recevons des retours très rapidement lorsque nous avons besoin d’aide et les cours sont parfaitement structurés. Les projets réalisés sont très complets et intéressants également.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_yourOrder = () => `Récapitulatif de la commande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_believemyPremium = () => `Abonnement à Believemy Premium`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_subtotal = () => `Sous-total`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_vat = () => `TVA`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_total = () => `Total (TTC)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_week = () => `Soit par semaine`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_totalForToday = () => `Total à régler aujourd'hui`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_noPaymentToday = () => `Essai gratuit de 3 jours`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_noPaymentTodayDescription = () => `Le montant de la TVA collectée peut différer selon votre adresse de facturation, cependant, le montant total TTC de votre commande ne changera pas.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_bankingInfos = () => `Vos coordonnées bancaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_bankingInfosDescription = () => `Ces informations ne sont pas stockées sur nos serveurs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_youCanStopAnytime = () => `Vous ne réglerez rien aujourd'hui, vous pourrez annuler votre essai à chaque instant.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_auth = () => `Commençons par vous authentifier`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_authDescription = () => `Si vous avez déjà un compte, nous allons vous connecter. Sinon, nous en créerons un.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_email = () => `Adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_password = () => `Mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_continue = () => `Continuer`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_billingInfos = () => `Votre adresse de facturation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_billingInfosDescription = () => `Ces informations ne sont utilisées que pour générer une facture suite à votre commande.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_firstName = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_lastName = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_street = () => `Rue`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_addressOption = () => `Rue (facultatif)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_postalCode = () => `Code Postal`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_city = () => `Ville`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_country = () => `Pays`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_payment = () => `Vos coordonnées bancaires`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pricing_paymentDescription = () => `Ces informations ne sont pas stockées sur nos serveurs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_meta_title = () => `Rejoignez les formateurs Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_meta_description = () => `Rejoignez l'équipe de formateurs Believemy et partagez vos connaissances avec le monde entier.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_subtitle = () => `Believemy for instructor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_title = () => `Rejoignez le mouvement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_headline = () => `Ne proposez pas juste une formation pour aider les autres, mais devenez un formateur Believemy à part entière et soyez rémunéré pour toute la valeur que vous créez, chaque mois, et tout ça pendant toute votre vie.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration = () => `Un système de rémunération juste et unique`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument1_title = () => `100% transparent`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument1_description = () => `Contrôlez en temps réel vos performances : utilisateurs actifs sur votre formation, minutes consommées, part de l'attention de votre formation sur l'ensemble du catalogue, paiements à venir... Tout est dans votre tableau de bord du formateur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument2_title = () => `Sans concurrence`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument2_description = () => `Nos formations ne sont pas en concurrence les unes avec les autres. Nous avons tellement confiance en ce que nos formateurs peuvent apporter que nous ne voulons pas qu'ils se battent pour les mêmes utilisateurs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument2_description2 = () => `Aucun risque donc de vous faire voler tout votre travail par des formateurs moins qualifiés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument3_title = () => `Une rémunération au plus juste`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument3_description = () => `Pourquoi partageriez-vous la part des utilisateurs que vous avez amené sur Believemy spécialement pour suivre votre formation avec d'autres formateurs déjà en place et ayant une part de l'attention bien plus grande ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument3_description2 = () => `Chez Believemy, vous êtes rémunéré selon la part de l'attention pour chaque utilisateur sur votre formation, et non pas pour la part de l'attention globale que vous possédez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_remuneration_argument3_description3 = () => `Par exemple si un utilisateur ne regarde que votre formation, vous obtiendrez 100% de sa part de l'attention, y compris si votre part de l'attention générale sur Believemy est de 0,1%.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example = () => `En pratique, ça donne quoi ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_description = () => `Voici un exemple de calcul de rémunération entre un formateur Believemy, un formateur sur une plateforme qui utilise un abonnement et un formateur sur une plateforme qui utilise un modèle de vente à l'unité. (Les chiffres sont des exemples pour des comparaisons plus ou moins similaires.)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first = () => `Vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_description = () => `Formateur Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two = () => `Pablo`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_description = () => `Formateur sur une plateforme avec abonnement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three = () => `Marie`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_description = () => `Formatrice sur une plateforme de vente à l'unité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_argument1 = () => `Vous avez créé une formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_argument2 = () => `50 utilisateurs sont venus spécialement sur Believemy pour suivre uniquement votre formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_argument3 = () => `Votre part de l'attention sur Believemy est de 0,1% car vous vous lancez`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_remuneration = () => `Votre rémunération`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_remuneration_argument1 = () => `50 utilisateurs x 100% de leur part de l'attention sur le mois, car ils n'ont suivi que votre formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_remuneration_argument2 = () => `50 x 100% de la part dédiée au fond des formateurs pour ces 5O utilisateurs (50% - en moyenne 33 € par utilisateur)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_first_remuneration_month = () => `Le premier mois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after = () => `Et après ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_argument1 = () => `Sur vos 50 utilisateurs, 25 d'entre eux continuent de ne suivre que votre formation. Les autres utilisateurs s'intéressent aussi à d'autres formations en parallèle, ils vont sur votre formation pour environs 50% de leur temps.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_argument2 = () => `Environs 30 nouveaux utilisateurs vous découvrent sur Believemy et suivent maintenant votre formation sur environs 70% de leur temps.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_argument3 = () => `Le mois suivant, vous aurez donc 100% de 25 utilisateurs, 50% de 25 utilisateurs et 70% de 30 utilisateurs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_argument4 = () => `Comme vous êtes un formateur Believemy, vous êtes rémunéré pour la part de l'attention individuelle de chacun des utilisateurs sur votre formation. Vous touchez donc environs 25 x 33 € + 25 x 16,5 € + 30 x 23,1 €.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_month = () => `Le mois suivant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_argument1 = () => `Il a créé une formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_argument2 = () => `5 utilisateurs sont venus spécialement sur sa plateforme pour suivre sa formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_argument3 = () => `Sa part de l'attention sur sa plateforme est de 0,1% car il se lance`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_remuneration = () => `Sa rémunération`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_remuneration_argument1 = () => `Pablo détient 0.1% de la part de l'attention globale, car les gros formateurs déjà en place accaparent l'attention avec leurs formations`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_two_remuneration_argument2 = () => `Il a donc 0.1% du fond dédié aux formateurs. En reprenant notre exemple, malgré les 50 utilisateurs qu'il a ramené sur sa plateforme, si celle-ci a un fond des formateurs de 100 000 €, il ne touchera que 100 €.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_two_argument1 = () => `Sur ses 50 utilisateurs, 25 d'entre eux continuent de ne suivre que sa formation. Les autres utilisateurs s'intéressent aussi à d'autres formations en parallèle, ils vont sur sa formation pour environs 50% de leur temps.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_two_argument2 = () => `Environs 30 nouveaux utilisateurs le découvrent sur sa plateforme et suivent maintenant sa formation sur environs 70% de leur temps.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_two_argument3 = () => `Le mois suivant, avec un peu de chance si aucun gros formateur n'a sorti une nouvelle formation, il aura donc peut-être réhaussé sa part de l'attention globale. Soyons particulièrement optimiste et doublons donc sa part de l'attention globale. Il passe de 0.1% à 0.2%.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_two_argument4 = () => `Comme il n'est pas un formateur Believemy, il est donc rémunéré selon sa part de l'attention globale . Il a donc 0.2% du fond dédié aux formateurs. En reprenant notre exemple, malgré les 50 utilisateurs qu'il a ramené sur sa plateforme, si celle-ci a un fond des formateurs de 100 000 €, il ne touchera que 200 €.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_argument1 = () => `Elle a créé une formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_argument2 = () => `50 utilisateurs sont venus spécialement sur sa plateforme pour acheter sa formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_argument3 = () => `Elle vend sa formation à 30 € (car au-dessus, elle est en concurrence avec les formations déjà bien en place)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_remuneration_argument1 = () => `Elle gagne en moyenne 70% du prix de vente, car sa plateforme prend aussi une commission d'environs 30% pour la gestion de la plateforme.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_example_three_remuneration_argument2 = () => `Elle gagne donc 50 x 70% de 30 €, soit 50 x 21 €`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_oneTimeOnly = () => `Une seule fois`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_three_argument1 = () => `Comme elle vend sa formation une seule fois, elle ne gagne théoriquement plus rien. Elle doit donc continuer à se démener pour vendre sa formation à d'autres personnes.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_three_argument2 = () => `Soyons optimiste et disons qu'en l'échange de tout son temps, même après l'effervecence de la sortie de sa formation, elle arrive à vendre encore 50 formations par mois.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_three_argument3 = () => `Le mois suivant, elle gagne donc 50 x 70% de 30 €, soit 50 x 21 €.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_after_three_argument4 = () => `En revanche, elle doit maintenant répondre aux questions des 100 étudiants sur sa formation (50 le premier mois + 50 le mois suivant), pour la même rémunération. Elle a donc doublé son temps de travail pour la même rémunération.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq = () => `Vous avez une question ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_question1 = () => `Comment se passe le réglement de mes paiements à venir ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_answer1 = () => `Vous recevrez tous les mois un mail récapitulatif détaillé de vos performances sur le mois précédent, ainsi que le montant total à nous facturer. Nous pourrons alors vous envoyer un virement bancaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_question2 = () => `De quoi est composé le fond des formateurs ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_answer2 = () => `Le fond des formateurs est composé de 50% de chaque mensualité des utilisateurs actifs sur le mois. Cela permet de rémunérer les formateurs de manière équitable et de garantir une qualité de service optimale.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_question3 = () => `Que se passe-t-il une fois ma proposition de formation acceptée ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_faq_answer3 = () => `Une fois votre proposition de formation acceptée, vous recevrez un mail vous expliquant les démarches pour activer votre compte formateur. Ces démarches sont simples et rapides, il vous suffira de signer le contrat de formation entre vous et Believemy et de nous envoyer une copie de votre carte d'identité. Vous pourrez ensuite créer votre formation et recevoir un accès pour héberger vos vidéos sur notre plateforme.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinInstructor_haveAnotherQuestion = () => `J'ai une question`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_meta_title = () => `Devenez mentor Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_meta_description = () => `Devenez mentor Believemy et accompagnez des étudiants dans leur formation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_subtitle = () => `Believemy for mentor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_title = () => `Devenez mentor`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_headline = () => `Vous voulez faire une différence ? Vous avez des compétences uniques et une passion pour aider les autres ? Rejoignez le mouvement et devenez mentor sur Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_doWhatYouLove = () => `Faites ce que vous aimez.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_doWhatYouLoveSubtitle = () => `On s'occupe du reste.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourTime = () => `Gestion de vos disponibilités`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourTimeDescription1 = () => `Vous êtes libre de choisir vos disponibilités : n'importe quel jour, à n'importe quelle heure, et à n'importe quel endroit grâce à l'immense gestion de fuseaux horaires que nous proposons.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourTimeDescription2 = () => `En connectant votre calendrier personnel, vous pourrez facilement suivre et bloquer vos créneaux.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourMoney = () => `Prise de rendez-vous et paiement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourMoneyDescription1 = () => `Définissez votre tarif par appel, le nombre de minutes et le tour est joué. Vous serez averti par mail ainsi que sur votre calendrier pour chaque nouveau rendez-vous de mentorat.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourMoneyDescription2 = () => `Avec les invitations personnalisées, il est même possible de proposer des appels gratuitement / à prix réduits ou majorés.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourWarranties = () => `Garanties et assurances`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourWarrantiesDescription1 = () => `Déplacements de rendez-vous, annulations gratuites ou non, tout est possible et personnalisable entièrement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourWarrantiesDescription2 = () => `Choisissez les conditions de vos rendez-vous et laissez-nous nous occuper de tout le reste. Concentrez-vous sur ce qui vous plait vraiment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_call = () => `L'essentiel, c'est vous deux.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_callImage = () => `Appel entre un mentor et son correspondant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_all = () => `Une plateforme clé en main`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_neverMiss = () => `Plus aucun rendez-vous qui se chevauche`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_neverMissDescription1 = () => `Ne prenez plus jamais un rendez-vous en doublon grâce à Believemy : en couplant votre agenda avec notre technologie de vérification en temps réel et vos préférences de disponibilités, vous êtes assuré de ne jamais être dans l'embarras face à un rendez-vous que vous ne pouvez pas maintenir.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_neverMissDescription2 = () => `Vous pourrez même choisir de laisser un petit peu de temps avant ou après un appel. Juste au cas où.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_inviteForWorldwide = () => `Une ouverture sur le monde`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_inviteForWorldwideDescription1 = () => `Grâce à notre technologie de gestion de fuseaux horaires, vous pourrez proposer des rendez-vous à n'importe quel moment de la journée, n'importe quel jour de la semaine, et n'importe où dans le monde.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_inviteForWorldwideDescription2 = () => `Chaque personne verra vos disponibilités en fonction son propre fuseau horaire.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourInvitations = () => `Des invitations sur demande`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourInvitationsDescription1 = () => `Envie de profiter des technologies de Believemy sans pour autant faire payer votre correspondant ? Envoyez-lui simplement une invitation personnalisée pour un appel gratuit.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourInvitationsDescription2 = () => `Vous pouvez également proposer des invitations à prix réduits ou majorés, pour un certains nombre de personnes ou non ; ainsi que pour une date limite ou non.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourCalls = () => `Une gestion au millimètre`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourCallsDescription1 = () => `Délai minimum de prévenance avant votre nouvel appel, durée des appels, prix, et bien plus encore : tout est optimisé pour convenir à votre emploi du temps et à vos préférences.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourGuarantees = () => `Des garanties flexibles`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourGuaranteesDescription1 = () => `Annulation gratuite ou non, déplacement de rendez-vous, et bien plus encore : vous avez le contrôle total sur les conditions de vos rendez-vous.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourGuaranteesDescription2 = () => `Et parce que nous faisons les choses bien, il vous est possible de tout spécifier : délais minimums, nombre de changements autorisés, délais maximums et même votre propre taux de remboursement.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourVisio = () => `Gagnez du temps`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourVisioDescription1 = () => `Pour que chaque appel soit une partie de plaisir et afin de vous faire économiser un temps précieux, nous avons mis en place un système de gestion des visioconférences.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourVisioDescription2 = () => `Vous n'avez plus à vous soucier de la plateforme à utiliser, de l'envoi des liens, de la création des appels, de la gestion des participants, de la gestion des enregistrements, ... tout est géré par Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourPayments = () => `Une rémunération (presque) instantanée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourPaymentsDescription1 = () => `Un appel terminé ? Vous êtes rémunéré. C'est aussi simple que ça.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_manageYourPaymentsDescription2 = () => `Nous avons mis en place un système de rémunération instantanée. Dès que votre appel est terminé, le montant de la rémunération pour cet appel est ajouté à notre liste des virements à effectuer dès réception des fonds.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq = () => `Vous avez une question ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_one = () => `Comment se passe la réservation d'un appel de mentorat ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_one = () => `Une personne qui souhaite réserver un appel de mentorat avec vous devra choisir son créneau d'après vos disponibilités et payer le prix défini par vos soins. Vous recevrez ensuite un mail vous informant de la réservation. Si vous avez connecté un calendrier à votre compte, le créneau sera automatiquement bloqué.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_two = () => `Comment se passe le paiement de mes rendez-vous de mentorat ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_two1 = () => `Chaque appel de mentorat payant transite par notre intermédiaire afin d'assurer une qualité optimale de transaction. Ceci nous permet notamment de rembourser instantanément les appels annulés. Lorsque vous terminez un appel, la rémunération correspondante est ajoutée à la liste des virements à effectuer.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_two2 = () => `La somme vous est ensuite directement versée sur votre compte bancaire, par virement, dans un délai de 1 à 10 jours.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_two3 = () => `Notez que des frais de commission de 11% et des frais de transaction de 4% sont appliqués pour chaque appel de mentorat payant. Ces frais de commission sont réduits à 6% pour les mentors également formateurs sur Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_three = () => `Y a-t-il quelque chose que je dois faire avant de prendre un nouvel appel ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_three1 = () => `Believemy s'occupe entièrement de l'expérience entre vous et votre correspondant. Ainsi, vous n'avez pas besoin de créer une visioconférence à chaque fois que vous prenez un appel. Nous nous occupons de tout.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_three2 = () => `Les déplacements de rendez-vous sont automatiquement gérés par notre système. Si vous avez connecté un calendrier à votre compte, les créneaux de rendez-vous seront automatiquement bloqués. Notez que vous êtes tout à fait libre de ne pas proposer cette garantie pour vos appels de mentorat.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_answer_three3 = () => `Toute demande d'annulation pour un appel de mentorat - si vous avez activé cette garantie - est intégralement gérée par Believemy. Vous n'êtes impliqué dans aucune étape préparatoire ni même de suivi : nous nous chargeons de tout. Si vous avez des demandes très précises, vous pourrez contacter votre référent Believemy qui sera ravi de vous aider.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const joinMentor_faq_another = () => `J'ai une question`


/**
 * @param {{ firstName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const UserLayout_bye = (params) => `À bientôt ${params.firstName} !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_meta_title = () => `Mentions légales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_meta_description = () => `Mentions légales de Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_title = () => `Mentions légales`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_director = () => `Directeur de la publication`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_fullname = () => `Prénom et NOM : Louis-Nicolas LEUILLET`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_email = () => `Adresse email : par formulaire de contact`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_SIREN = () => `SIREN: 950 731 331`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_address = () => `Adresse : 229 rue Saint-Honoré, 75001 Paris (France)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_NDA = () => `NDA: 11756755675`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hosting = () => `Hébergement du site believemy.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingCompany = () => `OVH`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingAddress = () => `Adresse : 2 rue Kellermann, 59100 Roubaix (France)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingPhone = () => `Téléphone : +33 (0)8 203 203 63`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingEmail = () => `Adresse email : support@ovh.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingBisCompany = () => `Vercel`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingBisAddress = () => `Adresse : 650 California Street, San Francisco, CA 94108 (États-Unis)`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_hostingBisEmail = () => `Adresse email : security@vercel.com`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_others = () => `Documents annexes`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_others1 = () => `Conditions générales d'utilisation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legalMentions_others2 = () => `Politique de confidentialité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_meta_title = () => `Conditions générales de vente et d'utilisation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_meta_description = () => `Conditions générales de vente et d'utilisation de Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_title = () => `Conditions générales de vente et d'utilisation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const termsOfSale_alert = () => `Cette page est uniquement disponible en français. Veuillez nous excuser pour la gêne occasionnée.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_meta_title = () => `Politique de confidentialité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_meta_description = () => `Politique de confidentialité de Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy_title = () => `Politique de confidentialité`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_meta_title = () => `Presse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_meta_description = () => `Découvrez tout ce qu'il y a à savoir sur Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_title = () => `Débrider le potentiel de chacun`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_headline = () => `Nos grandes dates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date1 = () => `1er novembre 2018`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date1_description = () => `Nous lançons notre site internet pour célébrer les 5 000 premiers étudiants formés sur des plateformes pour lesquelles nous sommes partenaires ce même jour. Nous ne sommes à cette date qu'une micro-entreprise liée à notre fondateur Louis-Nicolas Leuillet.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date2 = () => `10 août 2019`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date2_description = () => `10 000 personnes sont formées !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date3 = () => `12 mai 2020`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date3_description = () => `20 000 personnes sont passées par nos formations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date4 = () => `13 juin 2020`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date4_description = () => `Nous lançons la troisième version de notre site internet : lancement de la plateforme de formation à la demande directement depuis Believemy. Nous proposons désormais nos propres certifications de fin de formation.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date5 = () => `12 mars 2021`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date5_description = () => `La quatrième version de Believemy ouvre la fonctionnalité de gestion de tâches, les groupes et les profils. Nous lançons également Believemy PRO, un abonnement spécialement réalisé pour rendre plus accessible l'accès à nos formations grâce à un paiement mensuel.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date6 = () => `15 avril 2021`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date6_description = () => `Nous fêtons les 30 000 personnes formées sur nos formations.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date7 = () => `11 mai 2021`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date7_description = () => `BeBot, notre petite mascotte, voit le jour. Elle se fera rare et surtout affichée sur la communauté Discord de Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date8 = () => `13 juin 2022`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date8_description = () => `Nous lançons la cinquième version de Believemy, qui refond l'interface utilisateur.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date9 = () => `1er mars 2023`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date9_description = () => `Believemy passe en EURL, au capital social de 14 000 euros.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date10 = () => `1er mai 2023`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date10_description = () => `Toujours dans l'optique de rendre l'interface plus accessible, nous lançons la sixième version de Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date11 = () => `21 février 2024`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date11_description = () => `Lancement de la septième version de Believemy. Cette version est une refonte complète de l'expérience utilisateur, le lancement du service de mentorat, la transformation des Programmes en Accélérateurs, l'ajout des séries et des formateurs partenaires.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date12 = () => `30 avril 2024`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date12_description = () => `Believemy est désormais certifié Qualiopi.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date13 = () => `2 décembre 2024`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_date13_description = () => `Lancement de la version 9 de Believemy, incluant l'ouverture de la plateforme à tous les formateurs et à l'internationalisation de la plateforme.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_contact = () => `Contactez-nous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_contact_description = () => `Pour toute demande de presse, d'interview ou de partenariat, contactez notre équipe sur press@believemy.com.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_contact_address = () => `Vous pouvez également nous envoyer un beau courrier à nos bureaux :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_pressKit = () => `Press Kit`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_pressKitDescription = () => `Vous souhaitez réaliser un article ou une production sur nous ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const press_pressKitDownload = () => `Téléchargez nos ressources`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_meta_title = () => `Communauté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_meta_description = () => `Trouvez des réponses à vos questions et partagez vos connaissances avec la communauté Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_title = () => `Communauté`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const community_subtitle = () => `Trouvez des réponses à vos questions et partagez vos connaissances avec la communauté Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DiscussionCard_replies = () => `Réponses`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DiscussionCard_reply = () => `Réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DiscussionCard_participants = () => `Participants`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const DiscussionCard_participant = () => `Participant`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityComponent_noDiscussions = () => `Il n'y a aucune discussion pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityComponent_filters_all = () => `Toutes les discussions`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityComponent_filters_solved = () => `Résolues`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityComponent_filters_unsolved = () => `Non résolues`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_error = () => `Veuillez entrer un message.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_success = () => `Message ajouté avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_confirmDeleteDiscussion = () => `Voulez-vous vraiment supprimer cette conversation ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_discussionDeleted = () => `Discussion supprimée avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_confirmDelete = () => `Voulez-vous vraiment supprimer ce message ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_messageDeleted = () => `Message supprimé avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_notMarkedForGuide = () => `Aide spontanée supprimée de cette discussion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_markedForGuide = () => `Aide spontanée ajoutée à cette discussion`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_bestMessage = () => `Discussion résolue`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_createdAt = (params) => `Publiée le ${params.date}`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_lastActivity = (params) => `Dernière activité le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_guide = () => `Aide spontanée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_bestAnswerAdded = () => `Réponse ajoutée comme meilleure réponse.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_bestAnswerRemoved = () => `Meilleure réponse retirée.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_messageUpdatedSuccessfully = () => `Message modifié avec succès.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_discussionUpdatedSuccessfully = () => `Discussion modifiée avec succès.`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_createdAt = (params) => `Publié le ${params.date}`


/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_updatedAt = (params) => `Modifié le ${params.date}`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_bestMessage = () => `Meilleure réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_removeBestMessage = () => `Retirer meilleure réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_markAsGuideButton = () => `Marquer comme aide spontanée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_unmarkAsGuideButton = () => `Retirer l'aide spontanée`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_writeYourMessage = () => `Tapez votre message`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_cancel = () => `Annuler`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_save = () => `Mettre à jour`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_course = () => `Formation :`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_seeCourse = () => `Voir la formation`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_noCourse = () => `Cette discussion vient d'un de nos accélérateurs.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_seeAccelerators = () => `Voir les accélérateurs`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_send = () => `Ajouter une réponse`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_signup = () => `Inscrivez-vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_or = () => `Ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_login = () => `Connectez-vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CommunityDiscussion_toReply = () => `pour répondre.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_meta_title = () => `Événements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_meta_description = () => `Découvrez les événements organisés par Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_text = () => `Believemy events`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_title = () => `Découvrez nos événements`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_subtitle = () => `En ligne, en direct, en présentiel ou en différé, découvrez tous nos événements.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_soon = () => `Prochainement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_noEvents = () => `Il n'y a aucun événement pour le moment.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_future = () => `Prochaines dates`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_date = () => `Date`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const events_parisTimezoneDate = () => `Heure de Paris`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_title = () => `Créez un compte gratuitement`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_google = () => `S'inscrire avec Google`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_else = () => `Sinon`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_firstname = () => `Prénom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_firstname_error = () => `Veuillez entrer votre prénom.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_lastname = () => `Nom`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_lastname_error = () => `Veuillez entrer votre nom.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_email = () => `Adresse email`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_email_error = () => `Veuillez entrer une adresse email valide.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_password = () => `Mot de passe`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_password_error = () => `Veuillez entrer votre mot de passe.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_terms = () => `En cliquant sur "Créer un compte", vous acceptez les conditions générales et la politique de confidentialité de Believemy.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_createAccount = () => `Créer un compte`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_or = () => `Ou`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_login = () => `Déjà un compte ?`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signup_loginNow = () => `Connectez-vous`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login_emailAlreadyUsed = () => `Vous avez déjà un compte avec cette adresse email. Vous ne pouvez pas vous connecter avec Google.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const maintenance_title = () => `Nous revenons bientôt.`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const maintenance_subtitle = () => `Believemy fait une petite cure de beauté et revient d'ici la fin de matinée.`


/**
 * @param {{ level: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Level_levelUp = (params) => `Bravo ! Vous avez atteint le niveau ${params.level} ! 🎉`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_articleUrl = () => `Copier le lien final`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const writer_article_link_copy = () => `Lien copié !`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rss_articles_fr_title = () => `Articles en français sur Believemy`


/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rss_articles_fr_description = () => `Découvrez les derniers articles en français sur Believemy.`
